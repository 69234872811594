import React, { useState, useEffect, useRef } from 'react';
import JobSection from './JobSection';

const RightPane = (props) => {

    let style = {
        display: props.showJobsMobile ? 'block' : 'none'
    }

    if (!props.isMobile) {
      style = {
          display: 'block'
      }
  }

  
  const chatStyle = {
      width: "calc(100% - 40px)",
      height: "77.5%",
      fontWeight: "bold", 
      color:"#fff",
      fontSize: 50,
      display: "flex",
      flexDirection: "column", // Added this to stack elements vertically
      justifyContent: "center",
      alignItems: "top",
      textAlign: "center",
      position: "absolute",
      marginLeft: 20,
      marginRight: 20,
      zIndex: 100
  }
  
    return (
      <div className="rightPane"style={style}>
          <div className='right-title'>Job Board</div>
          {props.jobs && props.jobs.length > 0 && <div>
          {props.jobs.map((job, index) => (
            <div key={index} className="job-main">
              <JobSection job={job} index={index} deleteJob={props.deleteJob} />
            </div>
          ))}
          </div>}
          {(!props.jobs || props.jobs.length == 0) && <div className='no-chat' style={chatStyle}>
            <div style={{ fontSize: 50 }}>Task Runner</div>
            <div style = {{ marginTop: 30 }}>
              <div style = {{ fontSize: 12, padding: 20, paddingTop:0 }}>
                  This is where we will run all of your tasks. You write up a message. And choose if you want me to
                  package it up for you in a little bow.
              </div>
            </div>
            <div styl={{ }}>
                <img src="./background-boxes.png" style = {{ width: "120%", height: "120%", left: -20, top: 0, position: "absolute", zIndex: -5, opacity: 0.7}}  />
            </div>
          </div>}
      </div>
    );
  };
  
  
  export default RightPane;