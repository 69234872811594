import React, { useState, useEffect, Component } from "react";
import axios from 'axios'
import packageJson from '../../package.json';
import Recommend from "./recommend";
import User from "./User";
import '../bootstrap.css';

const api = axios.create({ "baseURL": packageJson.proxy });


class UserBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recommendation: {
                data: [],
                bio: ''
            },
            loading: false,
            user: {},
            statements: [],
            recommendationBackground: {
                width: '100%',
                height: '100%',
                borderRadius: 10,
                padding: 20,
                backgroundColor: 'unset'
            },
            values : [],
            maxWord: 15,
            firstSection: "60%",
            searchWidth: 300,
            sizeMobile:false,
            sizeSmall: false,
            sizeLarge: true,
            inputPaneHidden: false,
            title: "What's your next move?",
            Toggle: 'CREATE',
            processingText: '',
            currentStatement: '',
            oldStatement: '',
            addnewResultdata: {}
        }
        
        this.clickStatement = this.clickStatement.bind(this);
        this.nextHandler = this.nextHandler.bind(this);
        this.update = this.update.bind(this);
        this.pageSize = this.pageSize.bind(this);
        this.showInputPane = this.showInputPane.bind(this);
        this.execute = this.execute.bind(this);
        this.getUser = this.getUser.bind(this);
        // this.fetchStatmentsHandler = this.fetchStatmentsHandler.bind(this);
        // this.editStatmentsHandler = this.editStatmentsHandler.bind(this);
        // this.addAIResponseHandler = this.addAIResponseHandler.bind(this);
        this.fetchAIResponseHandler = this.fetchAIResponseHandler.bind(this);
        this.autoSetupInput = this.autoSetupInput.bind(this);
        this.noList = [ ".", ",", "?", ";", ":", "'", '"', "<", ">", "=", "-", "(", "*", "&", "^", "$", "#", "@", "!", "~", "`", "+", "_" ];
    }

    componentDidMount() {
        this.setState({ loading: true, processingText: 'fetching...' });
        this.autoSetupInput();
        this.pageSize();
        this.getUser();
        setTimeout(() => {
            // this.fetchStatmentsHandler();
            this.fetchAIResponseHandler();
        }, 2000);
    }

    getUser() {
        const us = new User();
        const /** @User */ userSet = us.setUser();
        this.setState({ user: userSet });
    }

    pageSize() {
        const sizeMobile = window.matchMedia("(max-width: 780px)").matches
        const sizeSmall = window.matchMedia("(min-width: 780px and max-width: 1000px)").matches
        const sizeLarge = window.matchMedia("(min-width: 1000px)").matches

        let firstSection = '';
        let searchWidth = 300;
        if(sizeMobile) { firstSection = "100%"; searchWidth = 0; }
        else if(sizeSmall) { firstSection = "60%"; searchWidth = 100; }  
        else if(sizeLarge) { firstSection = "60%"; searchWidth = 300; }

        this.setState({ firstSection: firstSection, 
                        sizeMobile: sizeMobile,
                        sizeSmall: sizeSmall,
                        sizeLarge: sizeLarge,
                        searchWidth: searchWidth });
    }

    clickStatement(e) {
        const current = e.target.value;
        this.setState({ oldStatement: current });
        this.update();
    }

    showInputPane() {
        if (this.state.inputPaneHidden) {
            this.setState({ inputPaneHidden: false, Toggle: 'CREATE', title: "What's you next move?" });
        }
        else 
        {
            this.setState({ inputPaneHidden: true, Toggle: 'BACK', title: "To make your next move ..." });
        }
    }


    autoSetupInput() {
        const stateElms = document.querySelectorAll(".state");
        let values = [];
        stateElms.forEach(elm => {
            values.push({ 
                id: elm.getAttribute('data-id'),
                statement: elm.value 
            });
        });

        this.setState({ values: values });
    }

    execute(e) {
        if (e.which == 13 || e.key == 13) {
            this.update();
            this.nextHandler();
        }
    }

    nextHandler() {
        let statements = this.update();

        let statementList = [];
        statements.forEach(phrase => {
            phrase = phrase.replace(/(([^\s]+\s+){15}).+/, "$1");
            if (phrase == '') return;

            let text = phrase;
            this.noList.forEach(nope => {
                text = text.replace(/[^a-zA-Z0-9 ]/g, '');
            });

            statementList.push(text);
        });

        this.setState({ statements: statementList });
        this.setState({ inputPaneHidden: false, Toggle: 'CREATE', title: "What's you next move?" });

        const userid = localStorage['userid'];
        let axiosConfig = {
          headers: {
                'Accept': 'Token',
                "Access-Control-Allow-Origin": "*",
                "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
                "Host": "https://us-central1-cofounderapi.cloudfunctions.net/",
                "Origin": "https://cofounderaiai.com",
                "Referer": "https://us-central1-cofounderapi.cloudfunctions.net/"
            }
        };

        let currentStatements = this.state.statements.filter(x => { if (x != "" && x != " ") return x; })

        let thisState = JSON.parse(JSON.stringify(this.state.recommendationBackground));
        thisState.backgroundColor = '#e8edf1'
        this.setState({ recommendationBackground: thisState, loading: true });
        this.setState({ processingText: "processing about 3 min ..." });

        const d = new Date();
        let month = d.getMonth() + 1;
        if (month < 10) {
            month = "0" + month;
        }

        let getDate = d.getDate();
        if (getDate < 10) {
            getDate = "0" + getDate;
        }

        let getHours = d.getHours();
        if (getHours < 10) {
            getHours = "0" + getHours;
        }

        let getMinutes = d.getMinutes();
        if (getMinutes < 10) {
            getMinutes = "0" + getMinutes;
        }

        let getSeconds = d.getSeconds();
        if (getSeconds < 10) {
            getSeconds = "0" + getSeconds;
        }

        const today = d.getFullYear() + "-" + month + "-" + getDate + " " + getHours + ":" + getMinutes + ":" + getSeconds;
        const topics = [
            "career",
            "college",
            "startup",
            "marketing",
            "investing"
        ];

        api({
            method: 'get',
            url: "/next-ai-step-api?statements=" + currentStatements.join('|') + "&u=" + this.state.user.uid + "&timestamp=" + today + "&user_topics=" + topics.join("|"), // next-ai-step?statements=" + this.state.statements.join('|'),
            headers: axiosConfig,
            data: { statements: currentStatements.join('|'), u: this.state.user, timestamp: today, uset_topics: topics.join("|")}
        }).then(response => { 
            // let thisState = JSON.parse(JSON.stringify(this.state.recommendationBackground));
            // thisState.backgroundColor = 'unset'

            // this.setState({ recommendation: response.data, addnewResultdata: response.data, recommendationBackground: thisState, loading: false });

            this.fetchAIResponseHandler();
            //this.addAIResponseHandler();
        });
    }

    // editStatmentsHandler(e) {
    //     const currentStatement = e.target.value;
    //     if (this.state.oldStatement == currentStatement) return;

    //     this.update();

    //     let statementList = [];
    //     const userid = localStorage['userid'];
    //     let axiosConfig = {
    //       headers: {
    //             'Accept': 'Token',
    //             "Access-Control-Allow-Origin": "*",
    //             "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8",
    //             "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    //             "Host": "https://us-central1-cofounderapi.cloudfunctions.net/",
    //             "Origin": "https://cofounderaiai.com",
    //             "Referer": "https://us-central1-cofounderapi.cloudfunctions.net/"
    //         }
    //     };

    //     const stateElms = document.querySelectorAll(".state");
    //     let valueset = [];
    //     stateElms.forEach(elm => {
    //         valueset.push({ 
    //             id: elm.getAttribute('data-id'),
    //             statement: elm.value 
    //         });
    //     });

    //     console.log(this.state.statements);
    //     console.log(valueset);

    //     let results = valueset.map(elm => {
    //         let val = elm.statement;
    //         if (val == '') return true;

    //         let text = val;

    //         if (this.state.statements.includes(text)) return false;
    //         console.log(this.state.statements.includes(text))

    //         return true;
    //     });

    //     console.log(results);

    //     this.setState({ values: JSON.parse(JSON.stringify(valueset)), loading: true });
    //     console.log('testing values', this.state.values);

    //     valueset.forEach(x => {
    //         if (x.statement != '') {
    //             statementList.push(x);
    //         }
    //     })

    //     console.log(statementList);
    //     this.setState({ processingText: "updating..." });

    //     api({
    //         method: 'post',
    //         url: "/edit-thought",
    //         headers: axiosConfig,
    //         data: { statements: JSON.stringify(statementList), user: JSON.stringify(this.state.user) }
    //     }).then(response => { 
    //         this.setState({ processingText: "" });
    //         this.fetchStatmentsHandler();
    //     });
    // }


    // fetchStatmentsHandler() {
    //     let axiosConfig = {
    //       headers: {
    //             'Accept': 'Token',
    //             "Access-Control-Allow-Origin": "*",
    //             "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8",
    //             "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    //             "Host": "https://us-central1-cofounderapi.cloudfunctions.net/",
    //             "Origin": "https://cofounderaiai.com",
    //             "Referer": "https://us-central1-cofounderapi.cloudfunctions.net/"
    //         }
    //     };

    //     const userset = JSON.stringify(this.state.user);
    //     const states = document.querySelectorAll(".state");
    //     this.setState({ processingText: "fetching..." });

    //     api({
    //         method: 'post',
    //         url: "/fetch_thoughts",
    //         headers: axiosConfig,
    //         data: { user: userset },
    //     }).then(response => { 
    //         let obj = response.data;
    //         let stateElms = obj.data;
    //         let newStatements = [];
    //         let values = [];

    //         stateElms.forEach(elm => {
    //             values.push({ 
    //                 id: elm.id,
    //                 statement: elm.statement 
    //             });

    //             newStatements.push(elm.statement);
    //         });
      
    //         let remain = states.length - values.length;
    //         if (remain < 0) remain = 0;
            
    //         for ( let i =0; i< remain; i++) {
    //             values.push({
    //                 id: '',
    //                 statement: ''
    //             });

    //             newStatements.push('');
    //         }

    //         const rows = document.querySelectorAll(".state");
    //         rows.forEach((elm, index) => {
    //             elm.value = values[index].statement;
    //             elm.setAttribute('data-id', values[index].id);
    //         });

    //         this.setState({ statements: newStatements,  loading: false, values: values, processingText: "" });
    //     });
    // }

    // addAIResponseHandler(e) {
    //     let statementList = [];
    //     const userid = localStorage['userid'];
    //     let axiosConfig = {
    //       headers: {
    //             'Accept': 'Token',
    //             "Access-Control-Allow-Origin": "*",
    //             "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8",
    //             "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    //             "Host": "https://us-central1-cofounderapi.cloudfunctions.net/",
    //             "Origin": "https://cofounderaiai.com",
    //             "Referer": "https://us-central1-cofounderapi.cloudfunctions.net/"
    //         }
    //     };

    //     this.setState({ processingText: "adding..." });

    //     api({
    //         method: 'post',
    //         url: "/add-ai-idea",
    //         headers: axiosConfig,
    //         data: { airesponse: JSON.stringify(this.state.addnewResultdata), user: JSON.stringify(this.state.user) }
    //     }).then(response => { 
    //         this.setState({ processingText: "" });
    //         this.fetchAIResponseHandler();
    //     });
    // }

    fetchAIResponseHandler() {
        let axiosConfig = {
          headers: {
                'Accept': 'Token',
                "Access-Control-Allow-Origin": "*",
                "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
                "Host": "https://us-central1-cofounderapi.cloudfunctions.net/",
                "Origin": "https://cofounderaiai.com",
                "Referer": "https://us-central1-cofounderapi.cloudfunctions.net/"
            }
        };

        const userset = JSON.stringify(this.state.user);
        this.setState({ processingText: "fetching..." });

        api({
            method: 'post',
            url: "/fetch-ai-ideas",
            headers: axiosConfig,
            data: { user: userset },
        }).then(response => { 
            console.log(response.data.data)
            this.setState({ processingText: "" });
            this.setState({ recommendation: response.data, loading: false }); 
            
            setTimeout(() => {
                const his_rows = document.querySelectorAll(".history-row");
                this.setState({ processingText: his_rows.length + " count" });
            }, 4000);
        });
    }


    update() {
        const StatementElms = document.querySelectorAll(".statement");

        this.setState({ statements: [] });
        let statementList = [];

        StatementElms.forEach(elm => {
            console.log(elm.value);
            elm.value = elm.value.trim();
            const last = elm.value.slice(-1);
            if (!["?", ".", "!", ",", ":"].includes(last) && elm.value !="") {
                elm.value = elm.value + ".";
            }  
            var phrases = elm.value.match( /[^\.!\?]+[\.!\?]+/g );

            phrases.map(val => { 
                val = val.replace(/(([^\s]+\s+){10}).+/, "$1");
                if (val == '') return;

                let text = val;
                this.noList.forEach(nope => {
                    text = text.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g,' ').trim();
                });

                if (text != "" && text.length > 10 && text.split(" ").length > 3) {
                    statementList.push(text.trim());
                }
            });
        });

        this.setState({ statements: statementList });
        return statementList;
    }


    render() {  
        window.addEventListener('resize', this.pageSize);

        const statements = JSON.parse(JSON.stringify(this.state.values))
        //console.log(statements);
        
        const {loading} = this.state;

        const body_style = {
            paddingTop: 50
        }

        const row_style = {
            paddingTop: 40
        }

        const pageTitleLabel = { 
            color: 'black', 
            backgroundColor: 'transparent', 
            padding: 20,
            paddingLeft: 0, 
            paddingTop: 35, 
            paddingBottom: 0, 
            width: 'fit-content',
            marginLeft: -16,
        }

        const headers = {
            marginLeft: -16
        }

        const inputbox = {
            marginTop: '1.1rem!important',
            paddingLeft: 20,
            paddingRight: 0,
            borderRadius: 4,
            padding: 30,
            width: window.innerWidth * 0.5,
            height: window.innerHeight - 250,
            color: "black",
            backgroundColor: "#fff",
            border: '1px solid #dbe0eb',
            fontSize: 18,
            
        }


        if (! this.state.sizeMobile) {
            const button_style = {
                position: 'absolute',
                left: 10,
                marginTop: 20,
                minWidth: '100px',
                backgroundColor:'#ff801e',
                border: '#ff801e',
                fontSize: 22
            }

            const inputboxsearch = {
                padding: 8,
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 40,
                backgroundColor: "transparent",
                width: this.state.searchWidth,
                border: '1px solid #71809f',
                color: "#fff",
                position: 'absolute',
                right: 250,
                top: 12
            }
        
        return (   
            
        <div style={body_style} className="body"  >
            {/* <input type='text' className='in-app search-it' style={inputboxsearch} placeholder="Seach thoughts on what's next"  onKeyUp={this.execute}></input> */}
            <div className='container-fluid' style={{ textAlign: 'left' }} >
                <div className="row" style={{borderTop: '2px solid #dbe0eb' }}>
                    <div id = "left-top-section" className='col-md left-top-section'  style={{ backgroundColor: "#fff", maxWidth: this.state.firstSection }}>
                        <div style={{ paddingLeft: 20, paddingRight: 0, paddingBottom: 80, position: "fixed" }}>
                        <h3 style={pageTitleLabel}>Playground</h3>
                            <div style={row_style}>
                                <div className="row">
                                    <div className='col-md'>
                                        <div className="row">
                                            <textarea type='text' 
                                                className='form-control mt-2 statement state' 
                                                style={inputbox} placeholder='What have you done so far in your life ...' 
                                                data-id='' 
                                                onClick={this.clickStatement}
                                                onBlur={this.update}>
                                            </textarea>
                                        </div>    
                                    </div>
                                </div>
                            </div>
                            <button className='btn btn-success' style={button_style} onClick={this.nextHandler}>Mint</button>
                        </div>
                    </div>
                    <div className='col-md' style={{ width: "20%" }}>
                        <div className='col-md'>
                            <h4 style={{ marginTop: 60 }}>History <small><code>{this.state.processingText}</code></small></h4>
                        </div>
                        <div style={this.state.recommendationBackground}>
                            {loading && <div class="spinner-border" role="status" style={{ position: 'absolute', textAlign: 'center', left: '50%', top: '50%'}}>
                                <span class="sr-only">Getting the results...</span>
                            </div>}
                            {this.state.recommendation.data?.map(function(recommendGroup, index) { 
                                return recommendGroup.data.map((row, j) => {
                                    return <Recommend key={j} dataset = {row} date={recommendGroup.date} />
                                })
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>)
        }
        else 
        {    
            const button_style = {
                position: 'fixed',
                right: 40,
                bottom: 50,
                marginTop: 20,
                minWidth: '100px',
                backgroundColor:'#ff801e',
                border: '#ff801e',
                fontSize: 22
            }

            const inputboxsearch = {
                padding: 8,
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 40,
                backgroundColor: "transparent",
                width: '90%',
                border: '1px solid #71809f',
                color: "#fff"
            }

            let showInputPanel = ''
            if (! this.state.inputPaneHidden) {
                showInputPanel = 'none';
            }

            return (<div style={{ backgroundColor: "#fff", fontSize: 40, height: 500, marginTop: 100, paddingTop: 200, width: '100%', textAlign: 'center', verticalAlign: 'middle' }}>
                Hey, this API is for desktop right now.
            </div>
            )
        }
    }
}
export default UserBoard;