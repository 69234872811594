import React, { useState, useEffect, useRef } from 'react';
import Threads from './Threads';
import Thread from './Thread';
import MessageBox from './MessageBox';
import FinancialChart from './FinancialChart';


import packageJson from '../../../package.json';
import axios from 'axios';
import PlaidButton from './PlaidAPI/PlaidButton';
import GoogleCalendarButton from './google/GoogleCalendarButton';

const api = axios.create({ "baseURL": packageJson.proxy });

const CenterPane = (props) => {
    let chat_messages = props.messages;

    const [topics, setTopics ] = useState([]);
    const [loading, setLoading] = useState(false);
    const [topicsData, setTopicData] = useState({});
    const [newMessage, setNewMessage] = useState(false);
    const [percentages, setPercentages] = useState({});
    const [mainGraphData, setMainGraphData] = useState([]);
    const [topicGraphData, setTopicGraphData] = useState({});
    const [showMoreTopics, setShowMoreTopics] = useState(false);
    const [moreTopicsDisplay, setMoreTopicsDisplay] = useState('more');
    const [clearPanel, setClearPanel] = useState(true);

    useEffect(() => {
      const fetchData = async () => {
        await getTopics();
      };
      fetchData();
    }, []);

    useEffect(() => {
        chat_messages = props.messages;
      }, [props.messages]);

    const [ internalWidth, setInternalWidth ] =  useState(230);

    let style = {
        display: !props.showJobsMobile ? 'block' : 'none'
    }

    if (!props.isMobile) {
        style = {
            display: 'block'
        }
    }

    useEffect(() => {
        const doc = document.querySelector(".centerPane");
        if (chat_messages && chat_messages.length == 0){
            
            doc.style.backgroundImage = 'unset';
            doc.style.backgroundColor = "#18191b";
        }
        else 
        {
            //doc.style.backgroundImage = '../images/type.webp';
            doc.style.backgroundColor = "#1e1f22";
            doc.style.backgroundColor = "#18191b";       
        }
    }, [chat_messages]);

    let width = "100%";
    let left = "0%"
    if (props.isMobile) {
        width = "100%";
        left = "0%"
    }

    const chatheader = {
        // borderBottom: '1px solid rgb(52, 52, 52)',
        width: "auto",
        position: "absolute",
        bottom: 120,
        paddingTop: 20,
        padding: 20,
        fontWeight: "bold",
        fontSize: 20,
        right: 0,
        textAlign: "right",
        backgroundColor: "transparent",
        zIndex: 305
    }

    let chatWindow = {
        backgroundColor: "transparent",
        marginTop: 0,
        marginBottom: 110,
        minHeight: "calc(87vh - 30px)",
        overflowY: "auto",
        overflowX: "hidden",
        position: "relative",
        marginRight: 'auto',
        marginLeft: 'auto',
    }

    chatWindow.minHeight = "calc(100vh - 50px)";
    if (props.isMobile) {
        chatWindow.minHeight = "calc(100vh - 20px)";
    }

    const chatStyle = {
        width: "100%",
        height: "100%",
        fontWeight: "bold", 
        color:"#383a4040",
        fontSize: 50,
        display: "flex",
        flexDirection: "column", // Added this to stack elements vertically
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        position: "absolute",
    }

    const chatContainerRef = useRef(null);

    useEffect(() => {
        scrollToBottom();

        //alert(JSON.stringify(chat_messages))

        window.addEventListener('orientationchange', function() {
            scrollToBottom();
            setTimeout(() => {
                scrollToBottom();
            }, 100);
        });

        document.addEventListener('resize', () => {
            scrollToBottom();
            setTimeout(() => {
                scrollToBottom();
            }, 100);
         });
    }, [chat_messages]);

    useEffect(() => {
        if (props.loading || (! props.isSPA && props.device == "iPhone")) {
            setInternalWidth(136)
        }
        else 
        {
            setInternalWidth(146);
        }        
    })

    const scrollToBottom = () => {
        if (topics.length == 0) {
            if (chatContainerRef.current) {
                chatContainerRef.current.scrollIntoView({ behavior: 'smooth' });
                document.querySelector(".chat-window-section").scrollTop = 0;
            }
        }
        else 
        {
            if (chatContainerRef.current) {
                chatContainerRef.current.scrollIntoView({ behavior: 'smooth' });
                document.querySelector(".chat-window-section").scrollTop = document.querySelector(".chat-window-section").scrollHeight;
            }           
        }
    };

    function getFirstName() {
        const user = JSON.parse(localStorage['user'])
        if (!user || !user.displayName) {
            return "";
        }

        let words = user.displayName.split(' ');
        let outputString = words[0];
        return outputString;
    }

    function addNewJob() {
        props.addJob({
            jobNo: "5kR93cB3S94L$g4h",
            jobName: "Job 1",
            jobStatus: "Running",
            jobType: "Chat",
            jobOwner: "John Doe",
            Jobs: [
                {
                    task: "Setup a flight to New York",
                    status: "Running",
                    progress: 0.5,
                    description: "Setup a flight so that Frank can be in new your by 5pm",
                },
                {
                    task: "Apply for a new job",
                    status: "Running",
                    progress: 0.2,
                    description: "Apply for a new job for Frank for an AI engineer"
                }
            ]
        });
    }

    function closeSideBars() {
        props.closeSideBars();
    }


    async function getTopics () {  
        if (localStorage['user'] == undefined) {
            setLoading(false);
    
            setTimeout(() => {
              getTopics();
            },  2000);
            return;
        }
  
        const user = JSON.parse(localStorage['user'])
        document.querySelector(".chat-update").classList.add("pulse");
        document.querySelector(".thinking").style.display = "inline-flex";
        // Replace 'api' with the actual API call function.
        api({
            method: 'get',
            url: `/main-topics-get?uid=${user.uid}`,
            headers: {
                'Accept': 'Token',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            },
            data: { uid: user.uid },
        })
          .then((response) => {
              const topicData = response.data[0];
              setTopicData(topicData);    
              setShowMoreTopics(true);

              if (topicData === undefined || topicData.topics === undefined || Object.keys(topicData.topics).length == 0) {
                let name = 'CofounderAI';
                props.updateChatMessages(false, `Hey ${user.displayName.split(" ")[0]}, what are your goals? What do you want to achieve?`, 
                name, Date.now(), "", "", "Goals", "Goals", [] );
            }
  
              const topicsList = Object.entries(topicData.topics)
                .sort(([, aValue], [, bValue]) => bValue - aValue)
                .map(([key, value]) => ({ [key]: value }));
  
              setTopics(topicsList);      



                // Todo: Frank compute each graph data set below. over time.
                const sortedFilteredData = {};
                let currentValue = 0;
                let currentTime = new Date(); // get the current date and time
                
                for (const [topic, dataList] of Object.entries(topicData.topic_graph_list)) {
                  const sortedData = dataList.sort((a, b) => new Date(a.date) - new Date(b.date));
                  
                  const filteredData = sortedData.map(item => {
                    let val = parseFloat(item.value) * 100; 
                    if (val >= 0) {
                        currentValue += val;
                    }
                    else 
                    {
                        currentValue -= 0.15 * Math.abs(val);
                    }
                    
                
                    const dataDate = new Date(item.date);
                    const timeDifference = Math.abs(currentTime - dataDate); // time difference in milliseconds
                    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // convert to days
                
                    let timeFrame = "";
                
                    if (daysDifference <= 1) {
                      timeFrame = "within 1 day";
                    } else if (daysDifference <= 7) {
                      timeFrame = "within 7 days";
                    } else if (daysDifference <= 30) {
                      timeFrame = "within 30 days";
                    } else if (daysDifference <= 90) {
                      timeFrame = "within 90 days";
                    } else if (daysDifference <= 365) {
                      timeFrame = "within 365 days";
                    } else if (daysDifference <= 1825) {
                      timeFrame = "within 5 years";
                    } else {
                      timeFrame = "all time";
                    }
                
                    return { date: item.date, value: currentValue, timeFrame: timeFrame };
                  });
                  
                  sortedFilteredData[topic] = filteredData;
                }

                //alert(JSON.stringify(sortedFilteredData))

                setTopicGraphData(sortedFilteredData)

                // Todo: Compute the main Graph by adding all of the topic graph data together into one.
                let overallFilteredData = [];
                currentValue = 0;
                currentTime = new Date(); // get the current date and time
                
                Object.entries(topicData.topic_graph_list || {}).forEach(([topic, dataList]) => {
                    dataList.forEach(item => {
                        overallFilteredData.push({ date: item.date, value: isNaN(parseFloat(item.value)) ? 0 : parseFloat(item.value) });
                    });
                });
                
                overallFilteredData.sort((a, b) => new Date(a.date) - new Date(b.date));
                
                const finalFilteredData = overallFilteredData.map(item => {
                    let val = item.value * 100;
                    if (val >= 0) {
                        currentValue += val;
                    }
                    else 
                    {
                        currentValue -= 0.15 * Math.abs(val);
                    }

                    const dataDate = new Date(item.date);
                    const timeDifference = Math.abs(currentTime - dataDate); // time difference in milliseconds
                    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // convert to days
                    
                    let timeFrame = "";
                    
                    if (daysDifference <= 1) {
                        timeFrame = "within 1 day";
                    } else if (daysDifference <= 7) {
                        timeFrame = "within 7 days";
                    } else if (daysDifference <= 30) {
                        timeFrame = "within 30 days";
                    } else if (daysDifference <= 90) {
                        timeFrame = "within 90 days";
                    } else if (daysDifference <= 365) {
                        timeFrame = "within 365 days";
                    } else if (daysDifference <= 1825) {
                        timeFrame = "within 5 years";
                    } else {
                        timeFrame = "all time";
                    }
                    
                    return { date: item.date, value: currentValue, timeFrame: timeFrame };
                });

                //alert(JSON.stringify(finalFilteredData))

                setMainGraphData(finalFilteredData)
                setShowMoreTopics(false);


              document.querySelector(".chat-update").classList.remove("pulse");
              document.querySelector(".thinking").style.display = "none";
          })
          .catch((e) => { 
            setLoading(false); 
        });
      }

    
      async function getChatsByTopic (e) {  
        if (localStorage['user'] == undefined) {
            setLoading(false);
            return;
        }
  
        if (!e || !e.target) {
            return;
        }
  
        let topic = e.target.closest(".main-topic").getAttribute('topic');
  
        if (!topic) {
            return;
        }

        document.querySelector(".chat-window-section").scrollTop = 0;
        const user = JSON.parse(localStorage['user'])
        document.querySelector(".chat-update").classList.add("pulse");
        document.querySelector(".thinking").style.display = "inline-flex";
        // Replace 'api' with the actual API call function.
        api({
            method: 'get',
            url: `/main-chat-get-topics?uid=${user.uid}&topic=${topic}`,
            headers: {
                'Accept': 'Token',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            },
            data: { uid: user.uid, topic: topic },
        })
          .then((responses) => {
              const topicData = responses.data.sort((a, b) => {
                    return new Date(a.timestamp) - new Date(b.timestamp);
             });
              console.log(topicData);
              props.setMessages([], () => { });

  
            
              if (topicData.length == 0) {
                    setNewMessage(true)
              }
              else 
              {
                    setNewMessage(false);
              }

              topicData.forEach((res) => {
                  let name = res.agent ? 'CofounderAI' : user.displayName;
                  setTimeout(() => {
                      props.updateChatMessages(!res.agent, res.message, name, res.timestamp, res.id, res.messageBase, res.title, res.topic );
                  }, 20);            
              });

              
              document.querySelector(".chat-window-section").scrollTop = 0;
              document.querySelector(".chat-update").classList.remove("pulse");
              document.querySelector(".thinking").style.display = "none";
          })
          .catch((e) => { setLoading(false); });
      }
      
      const getChartPercentage = (topic, percent) => {
        setPercentages(prevPercentages => ({
          ...prevPercentages,
          [topic]: percent
        }));
      };

    const showTopicList = () => {
        setShowMoreTopics(!showMoreTopics);

        if (moreTopicsDisplay == "more") {
            setMoreTopicsDisplay("less");
        }
        else 
        {
            setMoreTopicsDisplay("more");
        }
    }

    const messagePanel = () => {
        if (clearPanel) {
            props.updateChatMessages(false, `Write what's on your mind ${getFirstName()}`, "CofounderAI", new Date().getTime(), "", "", "", "" )
            setClearPanel(false);    
        }
    }

    const handChatHomePage = () =>{
        props.handleNewChat();
        if(!clearPanel) {
            setClearPanel(true);
        }
    }
      

    return (
      <div className='centerPane' style={style} onClick={closeSideBars}>
          <div className="center-pane-header" style={chatheader}>   
            {!props.showDashboard && <span className='new-tab' onClick={handChatHomePage} >
                <svg height="48" width="48">
                    <polygon points="24,14 12,24 36,24" style={{ fill: props.userGrowthColor }} />
                    <rect x="16" y="24" rx="2" ry="2" width="16" height="10" style={{ fill: props.userGrowthColor }} />
                </svg>
            </span>}
            <span className='refresh-tab' onClick={props.handleUpdateChat} >
                <span className='chat-update'>
                    {/* <svg height="48" width="48">
                        <line x1="24" y1="15" x2="24" y2="33" style={{ stroke: props.userGrowthColor, strokeWidth: 2 }} />
                        <line x1="24" y1="35" x2="16" y2="31" style={{ stroke: props.userGrowthColor, strokeWidth: 2 }} />
                        <line x1="24" y1="35" x2="32" y2="31" style={{ stroke: props.userGrowthColor, strokeWidth: 2 }} />
                    </svg>   */}
                    <svg height="48" width="48">
{/*        
                        <rect x="8" y="8" rx="4" ry="4" width="28" height="16" style={{ fill: 'transparent', stroke: props.userGrowthColor, strokeWidth: 2 }} />
                        <line x1="8" y1="12" x2="36" y2="12" style={{ stroke: props.userGrowthColor, strokeWidth: 2 }} />
                        <polygon points="8,24 16,16 8,16" style={{ fill: 'transparent', stroke: props.userGrowthColor, strokeWidth: 2 }} /> */}

                        <rect x="10" y="16" rx="4" ry="4" width="24" height="16" style={{ fill: 'transparent', stroke: props.userGrowthColor, strokeWidth: 2 }} />
                        <line x1="10" y1="20" x2="34" y2="20" style={{ stroke: props.userGrowthColor, strokeWidth: 2 }} />
                        <polygon points="10,32 18,24 10,24" style={{ fill: 'transparent', stroke: props.userGrowthColor, strokeWidth: 2 }} />
                    </svg>
                </span>
            </span>
          </div>
          <div style={chatWindow} ref={chatContainerRef}>
              <div className="chat-window-section" style={{ position: "absolute", flexDirection: "column-reverse", top: 0, height: `calc(100vh - ${internalWidth}px)`, width: width, overflowX: "hidden", overflowY: "auto", left: left }}>
                  {!props.showDashboard && chat_messages.map((chat, index) => {
                      return <Thread key={index} index={index} chat={chat} score={chat.score} user={chat.user} currentUser={chat.isUser} timestamp = {chat.timestamp} addJob={props.addJob} isMobile={props.isMobile} userGrowthColor={props.userGrowthColor} deleteMessage = {props.deleteMessage}
                            handleUpdateChat = {props.handleUpdateChat} >
                          {chat.message}
                      </Thread>
                    })}
                    {!props.loading && props.showDashboard && <div style= {{ zIndex: 100 }}><FinancialChart setUserGrowthColor={props.setUserGrowthColor} width={window.innerWidth} height={window.innerHeight/3} spark={false} chart = {mainGraphData}  /></div>  }
                    {!props.loading && props.showDashboard && topics.length == 0 && <div style={{ display: "inline-flex", minWidth: window.innerWidth-20, backgroundColor: "transparent", border: "1px solid transparent", borderRadius: 0, zIndex: 0,  position: "absolute", top: window.innerHeight / 3 + 170 }}> 
                       <div style={{ margin: 20, backgroundColor: "#101114", borderRadius: 8, padding: 20, width: "100%"  }}>   
                            <div style = {{ position: "relative", fontSize: 20, textAlign: "center", marginBottom: 20,  marginLeft: 0, color: props.userGrowthColor }}> 
                                Write your first message
                            </div>
                            <div style={{ marginBottom: 20 , fontSize: 14 }}>Welcome to your CofounderAI, this is your Cofounder to help build you career:</div>
                            <div style = {{ display: "inline-flex", marginBottom: 10 }}>
                                <div style = {{ position: "relative", marginLeft: 30 }}> 
                                    <div>🚀 Startup</div>
                                    <div>👨🏼‍🔬 Professional</div>
                                    <div>🎓 College</div>
                                </div>
                            </div>
                            <PlaidButton />
                            <GoogleCalendarButton/>
                        </div>
                    </div>}
                    {chat_messages.length == 0 && topics.length != 0 && <div style={{ display: "none" }}>
                        <div style={{ display: "inline-flex", minWidth: window.innerWidth-20, backgroundColor: "transparent", border: "1px solid transparent", borderRadius: 0, zIndex: 0,  position: "absolute", top: window.innerHeight / 3 + 170 }}> 
                            <div style={{ margin: 20, backgroundColor: "#101114", borderRadius: 8, padding: 20, width: "100%"  }}>   
                                <div style = {{ position: "relative", fontSize: 20, textAlign: "center", marginBottom: 20,  marginLeft: 0, color: props.userGrowthColor }}> 
                                    Review Your Thoughts
                                </div>
                                <div style={{ marginBottom: 20 , fontSize: 14 }}>Welcome to your CofounderAI, this is your Cofounder to help build you career:</div>
                                <div style = {{ display: "inline-flex", marginBottom: 10 }}>
                                    <div style = {{ position: "relative", marginLeft: 30 }}> 

                                    </div>
                                </div>
                            </div>
                        </div>

                        {props.isMobile && <div className="inquiries" style = {{ display: "inline-block", marginTop: 150, paddingBottom: 70, position: "relative", top: window.innerHeight/2 + 100, zIndex: 1 }}>
                            <div style={{ fontSize: 24, color: "#fff", marginLeft: 20 }}>
                                {getFirstName()}'s Road Map
                            </div>
                            {topics.map((x, index) => {
                                const key = Object.keys(x)[0];
                                const value = Object.values(x)[0];

                                if (value == 0) return;

                                let currentPercentage = parseFloat(percentages[key]) || 0;
                                
                                if (index > 2 && (!showMoreTopics && currentPercentage == 0)) {
                                    return;
                                }
                                
                                let sparkColor = "#008000";
                                if (currentPercentage < 0) {
                                    sparkColor = "#e95333";
                                }
         
                                return <div className="main-topic" style={{ marginLeft: 0, marginRight: 20, fontWeight: "bold", zIndex: -100 }} topic={key} onClick={getChatsByTopic}>
                                    <div className = "newChat" key={index} topic={key} style={{ display: "inline-flex", minWidth: window.innerWidth-20, backgroundColor: "transparent", border: "1px solid transparent", borderBottom: "1px solid #26272a", borderRadius: 0, zIndex: -1 }}>
                                        <div topic={key} style={{ paddingTop: 10, color: "#fff", textAlign: "left", fontSize: 12, fontWeight: "normal", position: "relative" }} >
                                            <div style= {{ fontWeight: "normal", marginTop: 10, fontSize: 16 }}>{key}</div>
                                            <div style ={{ fontWeight: "normal", fontSize: 12, color: "grey" }}>{value} conversations</div>
                                        </div>
                                        <div style = {{ position: "absolute", right: 200 }} topic={key}>
                                            <div style={{ position: "relative", marginTop: -160 } }>
                                                <FinancialChart width={100} height={100} spark={true} topic = {key} getChartPercentage = {getChartPercentage} chart = {topicGraphData[key]} />
                                            </div>
                                        </div>
                                        <div topic={key} style={{ paddingTop: 5, width: 70, height: 35, marginTop: 10, marginRight: 20, borderRadius: 5, backgroundColor: "transparent", border: "1px solid " + sparkColor, color: "#fff", textAlign: "center", fontSize: 13, fontWeight: "normal", position: "absolute", right: 0, bootom: 0 }} >
                                            <span style ={{ fontWeight: "bold", color: sparkColor, fontSize: 16 }}>{currentPercentage.toFixed(1)}x</span>
                                        </div>
                                    </div>
            
                                </div>
                            })}
                            <div style = {{ zIndex: 201, padding: 20, borderRadius: 10, textAlign: "center", marginTop: 20, width: "100%", backgroundColor: "#101114" }} onClick={showTopicList}>{moreTopicsDisplay} ...</div>
                        </div>}
                    </div>}
                   {/* {chat_messages.length == 0 && <div className='no-chat' style={chatStyle}>Cofounder</div>  } */}
              </div>
          </div>
          <div onClick ={messagePanel} >
            <MessageBox getTopics={getTopics} user={props.user} messages = {props.messages} handleUpdateChat = {props.handleUpdateChat} updateChatMessages= {props.updateChatMessages} handleSearch={props.handleSearch} setSearchQuery = {props.setSearchQuery} inputRef = {props.inputRef} phoneType = {props.phoneType} userGrowthColor = {props.userGrowthColor}  />
          </div>
      </div> 
    );
};
  
  
  export default CenterPane;