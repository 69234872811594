import React, { useState, useEffect, useRef } from 'react';

const Threads = (props) => {

    let chat = props.chat;
    let backgroundColor = 'transparent';
    let border = 'unset';

    if (props.active) {
        backgroundColor = '#f0f0f0';
        border = '1px solid #f0f0f0';
    }

    let color = '#000000';
    let bold = 'normal';
    if (chat.read !== undefined && !chat.read) {
        color = '#000000';
        bold = 'bold';
    }

    const style = {
        display: 'inline-flex',
        margin: 10,
        padding: 5,
        paddingLeft: 20,
        paddingRigh: 20,
        borderRadius: 5,
        backgroundColor: backgroundColor,
        border: border,
        color: color,
        fontWeight: bold
    }

    const profileBlock = {
        fontSize: 10,
        fontWeight: "bold",
        marginRight: 10,
        marginTop: 10
    }

    let profileCircle = {};

    if (chat.image && chat.image !== '') {
        profileCircle = {
            width: 30,
            height: 30,
            borderRadius: "50%"
        }
    }
    else 
    {
        profileCircle = {
            width: 30,
            height: 30,
            borderRadius: "50%",
            fontWeight: "bold",
            backgroundColor: "#000000",
            color: "#ffffff",
            textAlign: "center",
            fontSize: 12,
            paddingTop: 5
        }
    }

    const nameBlock = {
        width: 234.8
    }

    const dateBlock = {
        fontSize: 14
    }

    function getFirstLetters() {
        let letters = [];
        let words = chat.name.split(' ');
        for (let i = 0; i < words.length; i++) {
            letters.push(words[i][0]);
        }

        return letters.join('').toUpperCase();
    }
  
    return (
        <div style={style}>
            <div style = {profileBlock}>
                {chat.image && chat.image !== '' ? <img src={chat.image} style={profileCircle} /> : getFirstLetters()}
            </div>
            <div style ={nameBlock}>
                <div>{chat.name}</div>
                <div style={{ fontSize: 12 }}>{chat.message}</div>
            </div>
            <div style={dateBlock}>{chat.date}</div>
        </div>
    );
  };
  

  export default Threads;