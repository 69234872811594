import React, { useState, useEffect, useRef } from 'react';
import { usePlaidLink } from 'react-plaid-link';


const PlaidButton = () => {
  const onSuccess = async (token, metadata) => {
    if (error) {
        console.error("Plaid error: ", error);
    }
    // send token to Python server
    console.info("Token: ", token);
    console.info("Metadata: ", metadata);
    const user = JSON.parse(localStorage['user'])

    fetch('https://us-central1-cofounderapi.cloudfunctions.net/pl_save_access_token', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ public_token: token, uid: user.uid }),
    })
    .then(response => response.json())
    .then(data => {
        console.log('Success:', data);
    })
    .catch((error) => {
        console.error('Error:', error);
    });
  };

  const style = {
     backgroundColor: 'green',
     textAlign:'center',
     
  }

  // Fetch the link_token from your server (replace with your server call)
  const fetchLinkToken = async () => {
    fetch('https://us-central1-cofounderapi.cloudfunctions.net/get-link-token')
      .then(response => response.json())
      .then(data => setLinkToken(data.link_token))
      .catch(error => {
        console.error('Error fetching link token: ', error);
      });
  };

  const [linkToken, setLinkToken] = useState(null);

  useEffect(() => {
    (async () => {
      const token = await fetchLinkToken();
    })();
  }, []);

  const config = {
    token: linkToken,
    onSuccess,
  };

  const { open, ready, error } = usePlaidLink(config);

  useEffect(() => {
    if (error) {
      console.error("Plaid error: ", error);
    }
  }, [error]);

  return (
      <div className="newChat" style = {style} onClick={() => open()}>
        Connect a bank account
      </div>
  );
};

export default PlaidButton;
