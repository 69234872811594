import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import packageJson from '../../../../package.json';
import { useSession, useSupabaseClient, useSessionContext } from '@supabase/auth-helpers-react';


//https://supabase.com/dashboard/project/zbhtflbnppfftfjhntal/auth/url-configuration
// https://www.youtube.com/watch?v=tgcCl52EN84

const api = axios.create({ "baseURL": packageJson.proxy });


const GoogleCalendarButton = () => {
    const [token, setToken] = useState(null);
    const [userImage, setUserImage] = useState(null);
    const [events, setEvents] = useState(null);


    const session = useSession();
    const supabase = useSupabaseClient();
    const { isLoading } = useSessionContext();

    useEffect(() => {
        if (session) {
            console.log(session.provider_token);
            console.log(session.provider_refresh_token)
            console.log(session);
            setUserImage(session.user.user_metadata.avatar_url);

            setTimeout(() => {
                StoreAccessToken();
            },100);

            getGoogleCalendarEvents();
        }
    }, [session]);

    async function googleSignIn() {
        const { error } = await supabase.auth.signInWithOAuth({
            provider: 'google',
            options: {
                scopes: 'https://www.googleapis.com/auth/calendar'
            }
        });

        if (error) {
            alert("Error: " + error.message);
            return;
        }
    }
    

    async function googleSignOut() {
        const { error } = await supabase.auth.signOut();

        if (error) {
            alert("Error: " + error.message);
        }
    }

    async function getGoogleCalendarEvents() {
        // https://console.cloud.google.com/apis/credentials?hl=en&project=cofounderapi
        api({
            method: 'GET',
            url: `https://www.googleapis.com/calendar/v3/calendars/primary/events`,
            headers: {
              'Accept': 'Token',
              "Authorization": 'Bearer ' + session.provider_token,
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
              'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            },
        })
        .then(response => {
            console.info(`status`,response)
            console.info(`session `,session)
            
            console.info(`token type ${session.token_type}`) //${session.provider_token}`)
            console.info(`Bearer ${session.provider_token}`) //${session.provider_token}`)
            return response.json()
        })
        .then(data => {
            console.info(data);
            setEvents(data.items);
        });
    }

    async function StoreAccessToken() {
        const user = JSON.parse(localStorage['user'])
        api({
            method: 'GET',
            url: `/set-calendar-token?uid=${user.uid}&token=${session.provider_token}&refresh_token=${session.refresh_token}`,
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
              'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            },

            data: {
                uid: user.uid,
                token: session.provider_token,
                refresh_token: session.refresh_token
            }
        })
        .then(response => {

            return response.json()
        })
        .then(data => {
            console.info(data);
        });
    }


    async function insertGoogleCalendarEvent(event) {
        const eventItem = {
            user_id: session.user.id,
            event_id: event.id,
            summary: event.summary,
            start: event.start,
            end: event.end,
            created: event.created,
            updated: event.updated,
            status: event.status,
            htmlLink: event.htmlLink,
            hangoutLink: event.hangoutLink,
            conferenceData: event.conferenceData,
        };

        await fetch("https://www.googleapis.com/calendar/v3/calendars/primary/events", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${session.provider_token}`, // Access token for google
                Accept: "application/json"
            },
            body: JSON.stringify(eventItem)
        })
        .then(response => {
            return response.json()
        })
        .then(data => {
            console.log(data);
        })
    }


    if (isLoading) return <div>Loading...</div>;


  return (
    <>
      <div style={{ borderRadius: 20, minWidth: "fit-content" }}>
        {session ? (
          <button  onClick={(e) => googleSignOut(e, token ? 'sign-out' : 'sign-in')} style = {{ borderRadius: 10, paddingLeft: 10, paddingRight: 10, minWidth: "100%", backgroundColor: "#fff", color: "#000000" }}>
            <img src={userImage} style = {{ borderRadius: "50%", height: 40, width: 40 }} alt="User" /> <img src="https://developers.google.com/identity/images/g-logo.png"  style = {{ borderRadius: "50%", height: 40, width: 40 }} alt="Google Icon" /> Signout of Calendar
            {events && events.map((event) => {
                return <div>{event.summary}</div>
            })}
          </button>
        ) : (
          <button onClick={(e) => googleSignIn(e, token ? 'sign-out' : 'sign-in')} style={{ borderRadius: 10, paddingLeft: 10, paddingRight: 10, minWidth: "100%", backgroundColor: "#fff", color: "#000000" }}>
            <img src="https://developers.google.com/identity/images/g-logo.png"  style = {{ borderRadius: "50%", height: 40, width: 40 }} alt="Google Icon" /> Signin to Calendar
          </button>
        )}
      </div>
    </>
  );
};

export default GoogleCalendarButton;
