import logo from '../logo-full.png' 

function UserHeader() {
    const style = {
        height: '90px',
        width: '100%',
        padding: 10,
        backgroundColor: '#fff',
        color: "#fff",
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 10
    }

    const logout_style = {
        position: 'absolute',
        right: 20,
        top: 20,
        backgroundColor:'#ff801e',
        border: '#ff801e'
    }

    const logo_style = {
        color: "#fff",
        fontSize: 15,
        position: 'absolute',
        left: "30px",
        top: 12
    }

    const logo_img = {
        top: 20,
        height: '50px',
        left: 0
    }

    const profile_style = {
        width: '45px',
        height: '45px',
        borderRadius: '50%',
        position: 'absolute',
        right: 100,
        top: 15
    }

    const nav_panel = {
        left: 20,
        position: 'absolute',
        top: 100,
        
    }

    const user = JSON.parse(localStorage['user'])
    const profile = user != null ? user.photoURL: '';
    console.log(user);
    return (<div style={style} className="header-section">
        <a href='https://www.cofounderai.com' style={logo_style}><img style={logo_img} src={logo} /></a>
        Cofounder
        <img style={profile_style} src={profile} />
        <button onClick={window['Logout']} style={logout_style} className="btn btn-success">Logout</button>
    </div>);
}


export default UserHeader;