import React, { useState, useEffect, useRef } from 'react';
import ActivityOptions from './ActivityOptions';

const ActivityBar = (props) => {

    useEffect(() => {


    }, []);

    const style = {

    }
  
    return (
      <div className='topicBar' style={style}>
          <ActivityOptions callChatAPI={props.callChatAPI} />
      </div>
    );
  };
  
  
  export default ActivityBar;