import React, { useState, useEffect, useRef } from 'react';
import './App.css';


  const AvailableMobileOnly = (props) => {
    const style = {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column", // Added this to stack elements vertically
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        position: "fixed",
        zIndex: 1000,
        fontSize: 50,
    };
  
    return (
        <div className="splash-screen" style={style}>
            <div>Only Available on Mobile for Now</div>
        </div>
    );
};


  
  export default AvailableMobileOnly;