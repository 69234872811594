import React, { useState, useEffect, Component } from "react";
import axios from 'axios'
import packageJson from '../../package.json';
import Nextstep from "./Nextstep";
import Recommend from "./recommend";

const api = axios.create({ "baseURL": packageJson.proxy });


class UserSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recommendation: {
                user_id: 0,
                data: [
                    
                ]
            }
        }

        this.nextHandler = this.nextHandler.bind(this);
    }

    nextHandler() {
        // axios.get('https://test-ouwxcyzesq-uc.a.run.app').then(response => {
        //     this.setState({ recommendation: response.data });
        // });

        const userid = localStorage['userid'];
        let axiosConfig = {
          headers: {
                'Accept': 'Token',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
                "Host": "https://us-central1-cofounderapi.cloudfunctions.net",
                "Origin": "https://app.cofounderai.com"
            }
        };

        api({
            method: 'get',
            url: "/test-1",
            headers: axiosConfig,
            data: { userid: userid }
        }).then(response => { 
            this.setState({ recommendation: response.data });
        });
    }

    render() {    
        const body_style = {
            paddingTop: 50
        }

        const row_style = {
            paddingTop: 20
        }

        const result_style = {
            width: '100%',
            height: '100%',
            borderRadius: 10,
            border: '1px solid lightgrey',
            padding: 20
        }

        const button_style = {
            position: 'absolute',
            right: 0,
            minWidth: '100px',
            backgroundColor:'#ff801e',
            border: '#ff801e'
        }

        return (
        <div style={body_style} className="body">
            <div className='container' style={{ textAlign: 'left' }} >
                <div className="row">
                <div className='col-md'>
                <div style={row_style}>
                    <h4>Think about what you want</h4>
                        <div className="row">
                            <div className='col-md'>
                                <div className="row">
                                <input type='text' className='form-control mt-2' placeholder='Current state in life #1'></input>
                                </div>
                            </div>
                        </div>
                </div>
                <button className='btn btn-success' style={button_style} onClick={this.nextHandler}>Run</button>
                </div>
                <div className='col-md'>
                    <div className='col-md'>
                        <h4>Ideas and Actions (recommended)</h4>
                    </div>
                    <div style={result_style}>
                        {this.state.recommendation.data.map(function(recommend, index) { 
                            return <Recommend key={index} dataset = {recommend} />
                        })}
                    </div>
                </div>
            </div>
        </div>
    </div>)
    }
}
export default UserSearch;