import React, { useState, useEffect, useRef } from 'react';
import ThreadFormat from './ThreadFormat';
import JobTask from './JobTask';

const JobSection = (props) => {
    let styleBlock = {

    }

    let profile = {

    }

    const [isHovered, setHovered] = useState(false);

    const handMouseEnter = () => {
        setHovered(true);
    }

    const handleMouseLeave = () => {
        setHovered(false);
    }

    let style = {

    }

    const buttonStyle = {
        marginLeft: '80%',
        marginTop: 30
    }

    const dateFont = {
        color: "grey",
        fontSize: 11,
        marginLeft: 10,
        textAlign: "right",
        width: "100%",
    }

    function getTime(property) {
        const now = new Date();

        if (!property || typeof property !== 'string') {
            return now.toLocaleTimeString();
        }
        
        const ttimestamp = new Date(property);

        if (ttimestamp.toDateString() === now.toDateString()) {
            return ttimestamp.toLocaleTimeString();
        }
        else 
        {
            return ttimestamp.toLocaleDateString(undefined, { dateStyle: 'short', timeStyle: 'short' });
        }
    }

    function deleteJobItem() {
        props.deleteJob(props.index);
    }


    function runResearch() {
        alert("The research function is not available yet.");
    }
 
    const job = props.job;
    return (
        <div className="job-section" style={styleBlock} key={props.index}>
            <div style ={style}>
                <div style={{ fontSize: 12, marginBottom: 5, fontWeight: "bold", minWidth: "fit-content", position: "relative", textAlign: "left" }}>
                    {props.user}
                    <span style={dateFont}>
                        started on {getTime(props.timestamp)}
                    </span>
                    <span onClick = {deleteJobItem} style = {{ fontSize: 25, color: "grey", position: "absolute", right: 10 }}>&times;</span>
                    <div style={{ marginTop: 15 }}>Job #{job.jobNo}</div>
                    <div style={{ fontSize: 16, marginTop: 5 }}><span style ={{ fontWeight: "normal" }}>Job Title: </span>{job.jobTitle}</div>

                    <div>
                        {job.Jobs.map((task, index) => {
                            return <JobTask key={index} index={index} task={task} />
                        })}
                    </div>
                    <div className = 'execute-job' style={buttonStyle} onClick={runResearch}>
                        Run
                    </div>
                </div>
            </div>
        </div>
    );
  };
  

  export default JobSection;