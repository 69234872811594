import React from 'react';


import YouTubePlayer from 'react-player/youtube';

const YouTubeVideo = ({ url, isMobile }) => {
    // Function to extract video ID from various YouTube URL formats
    const getVideoId = (url) => {
        const regExp =
          /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})(?:\S+)?$/;
        const match = url.match(regExp);
        return match && match[1];
    };

    const videoId = getVideoId(url);

    if (!videoId) {
        return <a href={url} target="_blank">{url}</a>;
    }

    return (
        <div style={{ marginTop: '20px', marginBottom: '20px', borderRadius: '10px', overflow: 'hidden', borderRadius: 25 }}>
            <div style={{ padding: '10px', backgroundColor: '#282a2f', paddingLeft: 0, paddingRight: 0 }}>
                {isMobile && <YouTubePlayer url={url} controls={true} width ={"100%" } height = "200px" />}
                {!isMobile && <YouTubePlayer url={url} controls={true} width ={"100%" } height = "450px" />}
            </div>
        </div>
    );
};

export default YouTubeVideo;