import React, { useState, useEffect } from "react";
import axios from 'axios'
import packageJson from '../../package.json';

function Nextstep(post, setPost) {
    //var [post, setPost] = useState({})
    useEffect(() => {
        axios.get('/data/nextstep').then((response) => {
            setPost(response.data);
        });
    }, []);

    return post;
}

export default Nextstep;