import React, { useState, useEffect, Component } from "react";

function NotAvailable() {

  let classes = {
    root: {
      backgroundColor: '#f5f5f5',
      textAlign: 'center',
      fontFamily: 'Arial, sans-serif',
      padding: 30,
      margin: 30
    },
    image: {
      marginTop: 10,
      width: '50%',
      borderRadius: '50%',
      boxShadow: '',
      '& img': {
        width: '100%',
        borderRadius: '50%',
      },
    },
    title: {
      marginTop: 0,
      fontSize: 40,
      color: '',
    },
    subtitle: {
      marginTop: 10,
      fontSize: 20,
      color: '',
    },
    link: {
      marginTop: 10,
      textDecoration: 'none',
      color: '',
    },
  };
  

  return (<div className="not-available">
    <div style={classes.root}>
      <div container alignItems="center" justify="center">
        {/* <Grid item xs={12}>
          <Image
            aspectRatio={1}
            src="https://images.unsplash.com/photo-1593642532857-b1eb24d9f5f1?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
            className={classes.image}
            disableSpinner
          />
        </Grid> */}
        <div item xs={12}>
          <div variant="h2" style={classes.title}>
            Welcome!
          </div>
        </div>
        <div item xs={12}>
          <div variant="h5" style={classes.subtitle}>
            We're glad you're here, but unfortunately our app is currently not available in your country.
          </div>
        </div>
        <div item xs={12}>
          <div color="secondary" variant="contained">
            <a href = "https://www.cofounderai.com">Back Home</a>
          </div>
        </div>
      </div>
    </div>
  </div>);
}

export default NotAvailable;