import React, { useState, useEffect, useRef } from 'react';


const OptionItem = (props) => {
    const [ selected, setSelected ] = useState(false);

    const style = {
        backgroundColor: selected ? '#383a40' : '#282a2f',
        padding: 15,
        borderBottom: '1px solid #383a40',
        cursor: 'pointer',
        textAlign: "left",
        paddingLeft: 30
    }

    function makeSelected() {
        if (!selected) {
            setSelected(true);
        }

        props.getSelectedId(props.dataKeyId, (key) => {
            if (props.dataKeyId == props.selectedId && !selected) {
                setSelected(true);
            }
        });

        props.callChatAPI(props.statement + " Find any past conversations I have said or talked about that helps me with this. **FORMAT THIS for a text message. Make sure it looks good to send as a text.", false);
        props.setOpen(false);
    }

    if (props.dataKeyId != props.selectedId && selected) {
        setSelected(false);
    }
  
    return (
        <div className="" style={style} onClick={makeSelected}>
            {props.children}
        </div>
    );
};


export default OptionItem;