import logo from './logo.svg'
import './bootstrap.css';
import './App.css';

import LoginNow from './components/LoginNow';
import CofounderApp from './components/CofounderApp';
import packageJson from '../package.json';

import { BrowserRouter  as Router, Route, Switch } from 'react-router-dom';
import "./font/static/Montserrat-Medium.ttf";
import mixpanel from 'mixpanel-browser';

//import Message from './components/message';


function App() {
  // or with require() syntax:
  // const mixpanel = require('mixpanel-browser');

  // Enabling the debug mode flag is useful during implementation,
  // but it's recommended you remove it for production
  mixpanel.init('58f562355fb906eee50c1741ada905bf', {debug: true}); 

  let login_section = {
      top: '40%',
      left: 'calc(50% - 180px)',
      position: 'fixed',
      backgroundColor: "#fff"
  }

  const body = document.querySelector("body");

  if (packageJson.appEnv == "app") { 
    body.style.backgroundColor = "#fff";
    login_section.backgroundColor = "#fff";
    if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        login_section.backgroundColor = "#141f25";
        body.style.backgroundColor = "#141f25";
    }  
  }

  if (packageJson.appEnv == "api") {
      login_section.backgroundColor = "#fff";
      body.style.backgroundColor = "#fff";
  }

  // if (packageJson.env == "prod") {
  //     console.log = function () {};
  // }

  return (
    <div className="App" onMouseUp={function(){ 
        const sizeMobile = window.matchMedia("(max-width: 500px)").matches;
        if (!sizeMobile) {  }
      }}>
        <div id="firebaseui-auth-container" style={login_section}></div>
        <LoginNow />
        <CofounderApp />
    </div>
  );
}

export default App;