import React, { useState, useEffect, useRef } from 'react';
import OptionItem from './OptionItem';


const LeftOption = (props) => {
    const [ open, setOpen ] = useState(false);
    const [ selectedId, setSelectedId ] = useState(0);
    const [callbackQueue, setCallbackQueue] = useState(null);
    const [todaysDate, setTodaysDate] = useState(null);
    const [nextWeeksDate, setNextWeeksDate] = useState(null);

    const style = {
        display: props.open ? 'block' : 'none',
        left: 0,
        top: 0,
        width: 'calc(100% - 50px)',
        maxWidth: 400,
        height: '100%',
        backgroundColor: '#282a2f',
        color: "lightgrey",
        position: "fixed",
        zIndex: 1,
        marginTop: 50,
        paddingTop: 50,
        fontSize: 20
    }

    const iconStyle = {
        backgroundColor: "#3a3a3b",
        maxWidth: 20,
        maxHeight: 20,
        borderRadius: "50%",
        padding: 12,
        paddingLeft:10,
        paddingRight: 10,
        marginRight: 10
    }
    
    const getSelectedId = (key, callback) => {
        setSelectedId(prevState => {
          callback(key);
          return key;
        });
      };

    useEffect(() => {
        // get todays date and store it into state
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth()+1; //January is 0 so need to add 1 to make it 1!
        let yyyy = today.getFullYear();

        // get the month name of the current month
        let monthName = new Intl.DateTimeFormat('en-US', { month: 'long'}).format(today);

        setTodaysDate(`${monthName} ${dd}, ${yyyy}`);

        // get the date of next week
        let nextWeek = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
        let nextWeekdd = nextWeek.getDate();
        let nextWeekmm = nextWeek.getMonth()+1; //January is 0 so need to add 1 to make it 1!
        let nextWeekyyyy = nextWeek.getFullYear();

        // get the month name of the current month
        let nextWeekMonthName = new Intl.DateTimeFormat('en-US', { month: 'long'}).format(nextWeek);

        setNextWeeksDate(`${nextWeekMonthName} ${nextWeekdd}, ${nextWeekyyyy}`);
    });

    return (
        <div style={style}>
        <OptionItem key="0" dataKeyId="0" getSelectedId = {getSelectedId} selectedId={selectedId} setOpen = {props.setOpen} callChatAPI={props.callChatAPI} 
            statement={`Today is ${todaysDate}. Give me a dashboard of info below:
            Give me a summary of everything in my life. Basically put it into a list format. Get these things from their respective sources: my finances, my calendar, my startup updates and progress, my career and job progress, calculate my networth, progress on my interaction with my immediate family, Collectively tell me my how all of this "impact my progress". Get this information for each item. Then summarize where each item should be in the next month given their current state. And rate me with a score between -1 to +1. Rate me against the best in each of these categories show where I am. Where 0 is the average person in the USA.
            `} >
            <span style={iconStyle}>〰</span> Dashboard
        </OptionItem>
        <OptionItem key="0" dataKeyId="0" getSelectedId = {getSelectedId} selectedId={selectedId} setOpen = {props.setOpen} callChatAPI={props.callChatAPI} 
            statement={`Today's date is ${todaysDate}. 
            Find out the latest news that's going on in the world and the US right now and tell me how it might impact me. Consider everything about me in regards to my job, my startup, my money, my business, my family and all.

            Replace this example with real data about me. 
            `} >
            <span style={iconStyle}>📰</span> News Updates for me
        </OptionItem>
        <OptionItem key="1" dataKeyId="1" getSelectedId = {getSelectedId} selectedId={selectedId} setOpen = {props.setOpen} callChatAPI={props.callChatAPI} 
            statement={`Today's date is ${todaysDate}.
            My Schedule
            
            Get my current schedule for this coming week between ${todaysDate} and ${nextWeeksDate} and include any details if any. If I have nothing today or this week, then say you have nothing. But focus on real data of what I have to do first. Give context if any and how I could do any of these task or events better. Show things that are unique. Consolidate information so things are redudant, this way novel things stick out. Don't just give a calendar.
            
            `} >
            <span style={iconStyle}>📆</span> Get My Schedule and Plan
        </OptionItem>
        <OptionItem key="2" dataKeyId="2" getSelectedId = {getSelectedId} selectedId={selectedId} setOpen = {props.setOpen} callChatAPI={props.callChatAPI} 
            statement='Financial Status:  Give me a dashboard of info below:
            
            Look at my personal finances and construct a table of where my money is and how I can make improvements. 

            Replace this example with real data about me. Give me my financial summary status update. Basically get all of income, transactions, investments, etc and give me a status on my inances right now. Tell me how my financial home and budget is looking. Then provide some ways to help make more money.
            '>
            <span style={iconStyle}>💰</span> Financial Status
        </OptionItem>
        <OptionItem key="3" dataKeyId="3" getSelectedId = {getSelectedId} selectedId={selectedId} setOpen = {props.setOpen} callChatAPI={props.callChatAPI} 
            statement='Profitability:  Give me a dashboard of info below:
            
            We need to make my startup profitable. Look into my startup and pull our everything about it: customers, revenue, expenses, traction, etc. Then once you know that figure out if I have made any progress over time, especially recently.
            
            ""
            "STATUS"
            Overall Progress
            Projected

            current revenue
            current MRR
            projected revenue next month
            current ARR
            what is annaul COGS
            
            overall cost
            CAC Cost in $ and improvement
            # customers
            churn
            customer growth

            Profit EBITA
            ""

            Replace this example with real data about me. Use the example above and fill in the real data for me and my real startup revenue and financials. this is not personal income but business income and expenses. Try to find this data and compute every piece even the projections.
            '>
            <span style={iconStyle}>🚀</span> Startup Status
        </OptionItem>
        <OptionItem key="6" dataKeyId="6" getSelectedId = {getSelectedId} selectedId={selectedId} setOpen = {props.setOpen} callChatAPI={props.callChatAPI} 
            statement='My Career:  Give me a dashboard of info below:
            
            You know my working condition. Provide me with support to help me progress in my career.
            1. I need you to analyze my current career position.
            2. Then I need you to evaluate the best next logical step either from what I said or what you think.
            3. Now provide resources to support what I should be doing.

            Provide the response in the format as shown below:

            ""
            Tasks:
            Next Offering: 
            New Annual Pay:
            Location:
            Position Title:
            People to Connect with:
            Technology to learn:
            ""        

            Provide every aspect of the skill or course that I need to take right now that will increase my overall score and look good in my career. Be mindful of my schedule, work and aspect of my career that this will help.
            '>
            <span style={iconStyle}>⚡</span> Career Flex
        </OptionItem>
        <OptionItem key="2" dataKeyId="2" getSelectedId = {getSelectedId} selectedId={selectedId} setOpen = {props.setOpen} callChatAPI={props.callChatAPI} 
            statement='Look at my finances and look at my income. Looks at all of my money that I have. Look at my investments and debt and loans. Look through all of that and tell me what you think is my networth. You have calculate this by getting all of the items line by mine. Then sum them up. Loans and debt is negative. Show all of your work. Make sure to look at any past messages for data as help.
            '>
            <span style={iconStyle}>💎</span> Evaluate my Net worth
        </OptionItem>
        <OptionItem key="4" dataKeyId="4" getSelectedId = {getSelectedId} selectedId={selectedId} setOpen = {props.setOpen} callChatAPI={props.callChatAPI} 
            statement="I need you to research the stock market and give me any stock positions that will do well in the next few weeks to maybe the next month or so. I am looking to invest money that will grow my investment portfolio.">
            <span style={iconStyle}>💹</span> Investing Options
        </OptionItem>
        <OptionItem key="5" dataKeyId="5" getSelectedId = {getSelectedId} selectedId={selectedId} setOpen = {props.setOpen} callChatAPI={props.callChatAPI} 
            statement="I need investors now who can help with funding my startup and help build out my company. I need you to package up the investor, the expected amount and the pitch that will most likely lock in the investment. If I have investments or currently working on investments, give me status on that. Be specific of how to aquire that funding. Any tactic possible.">
            <span style={iconStyle}>👨🏼‍🔬</span> Investors
        </OptionItem>
        <OptionItem key="7" dataKeyId="7" getSelectedId = {getSelectedId} selectedId={selectedId} setOpen = {props.setOpen} callChatAPI={props.callChatAPI} 
            statement='Family Improvement:  Give me a dashboard of info below:
            
            To keep a balanced life and knowing my current work conditions, today you have to help me make the best actions to make my closest loves feel loved and what can I do for them? Be specific knowing you know my closest family members and closest friends.

            Replace this example with real data about me. The "improvement" is what I have did or need to do. The "score" is how I am doing with this person. "Family Member" is the name of the person in my life such as spouse name or kid name or friend name or parent name or S.O. name.
            '>
            <span style={iconStyle}>👪</span> Family
        </OptionItem>
        <OptionItem key="8" dataKeyId="8" getSelectedId = {getSelectedId} selectedId={selectedId} setOpen = {props.setOpen} callChatAPI={props.callChatAPI} 
            statement='Courses and Education:
            
            You know if I am in college or not. Based on that where I am currently, please work with me on the 5 actionable researched steps that will get me towards graduation with a great job. Even if I have not started college or half way through. Get my previous messages of where I am and figure out what I need to do graduate and that that $200k job.
            
            Tell me about an education that I can learn or start learning to improve my skills or get through college.

            Skill
            Why it matters
            Where can I use it
            What company needs it
            How long it takes to learn
            How much does it cost
            Link to website of course or tool
            Best person to contact
            '>
            <span style={iconStyle}>👨🏼‍🎓</span> College
        </OptionItem>
      </div>
    );
};


export default LeftOption;