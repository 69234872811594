
import mixpanel from 'mixpanel-browser';

function LoginNow() {
    const user = JSON.parse(localStorage['user'])
    const uid = user != null ? user.uid: '';
    const userData = user != null ? user: {};

    if (user == null) {
      mixpanel.track(`Login Page`);
      return (
        <div id="login_box" className="login">
            
        </div>
      );
    }
    else 
    {
      return (<div></div>);
    }
}

export default LoginNow;