import Main from './Main'

function CofounderApp() {
  const user = JSON.parse(localStorage['user'])
  const uid = user != null ? user.uid: '';
  const userData = user != null ? user: {};

  if (user != null) {
    return (
      <div id="logout_box" className="logout">
          <Main />
      </div>
    );
  }
  else 
  {
      return <div></div>
  }
}

export default CofounderApp;