import React, { useEffect, useState }  from 'react';
import { LinkedIn, useLinkedIn } from 'react-linkedin-login-oauth2';

import axios from 'axios';
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import { json } from 'react-router-dom';
// npm install axios react-linkedin-login-oauth2


import packageJson from '../../../../package.json';
import { useSession, useSupabaseClient, useSessionContext } from '@supabase/auth-helpers-react';


//https://supabase.com/dashboard/project/zbhtflbnppfftfjhntal/auth/url-configuration
// https://www.youtube.com/watch?v=tgcCl52EN84

const api = axios.create({ "baseURL": packageJson.proxy });



const fetchLinkedInData = async (access_token) => {

    const headers = {
        'Authorization': `Bearer ${access_token}`,
        'host': 'api.linkedin.com'
    };

  
    try {
      const basic = await axios.get('https://api.linkedin.com/v2/me', { headers: headers });

      //alert(JSON.stringify(basic.data));



      // Basic profile information
      const profile = await axios.get('https://api.linkedin.com/v2/me?projection=(id,firstName,lastName,profilePicture(displayImage~:playableStreams),headline)', { headers });
  
      // Job Positions
      const positions = await axios.get('https://api.linkedin.com/v2/positions?projection=(elements*(id,title,description))', { headers });
  
      // Education
      const education = await axios.get('https://api.linkedin.com/v2/educations?projection=(elements*(id,schoolName,degreeName,fieldOfStudy))', { headers });
  
      // Certificates can be fetched if they are included in your application's LinkedIn permissions and the user has granted access.
      const certificates = await axios.get('https://api.linkedin.com/v2/certifications?projection=(elements*(id,name,authority))', { headers });
  
      // Extract relevant fields
      const {
          id,
          firstName: { localized: { en_US: firstName } = {} } = {},
          lastName: { localized: { en_US: lastName } = {} } = {},
          headline,
          // Add additional fields here
      } = profile.data;
  
      const jobTitle = positions.data.elements[0]?.title;
      const jobDescription = positions.data.elements[0]?.description;
  
      // Further processing
      let o = {
          id:id,
          firstname: firstName,
          lastname: lastName,
          headline: headline,
          jobTitle: jobTitle,
          jobDescription: jobDescription,
          education: education.data.elements,
          certificates: certificates.data.elements,
      };

      console.log(o);
      alert(JSON.stringify(o));
      localStorage.setItem('linkedin_user_data', JSON.stringify(o));

      return o;
      
    } catch (error) {
        console.error('Failed to fetch LinkedIn data:', error.message);
    }
};
  




const LinkedInAPIButton = () => {

    const [accessCode, setAccessCode] = useState(null);

    const [token, setToken] = useState(null);
    const [userImage, setUserImage] = useState(null);
    const [events, setEvents] = useState(null);


    const session = useSession();
    const supabase = useSupabaseClient();
    const { isLoading } = useSessionContext();

    useEffect(() => {
        if (session) {
            console.log(session.provider_token);
            console.log(session.provider_refresh_token)
            console.log(session);
            setUserImage(session.user.user_metadata.avatar_url);

            setTimeout(() => {
                //StoreAccessToken();
            },100);

            //getGoogleCalendarEvents();
        }
    }, [session]);

    async function linkedinSignIn() {
        const { error } = await supabase.auth.signInWithOAuth({
            provider: 'google',
            options: {
                scopes: 'https://www.googleapis.com/auth/calendar'
            }
        });

        if (error) {
            alert("Error: " + error.message);
            return;
        }
    }




//     useEffect(() => {
//         const urlParams = new URLSearchParams(window.location.search);
//         const code = urlParams.get('code');

//         if (code) {
//             localStorage.setItem('linkedinCode', code);
//             setAccessCode(code);
//         } else {
//             const localCode = localStorage.getItem('linkedinCode');
//             if (localCode) {
//                 setAccessCode(localCode);
//             }
//         }

//         if (accessCode) {
//             fetchLinkedInData(accessCode);
//         }
//     }, [accessCode]);



// const { linkedInLogin } = useLinkedIn({
//     clientId: '86ykb164n685zp',
//     redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
//     scope: "openid profile email",
//     closePopupMessage: true,
//     onSuccess: (code) => {
//         alert(code);
//         console.log(code);
//         localStorage.setItem('linkedinCode', code);
//         setAccessCode(code);
//         const user_linkedin = fetchLinkedInData(code);
//     },
//     onError: (error) => {
//         console.log(error);
//         alert(JSON.stringify(error));
//     },
// });


useEffect(() => {
  // Check localStorage for existing code and set state
  const storedCode = localStorage.getItem('linkedin_auth_code');
  if (storedCode) setAccessCode(storedCode => fetchLinkedInData(storedCode));
}, []);

  
const openLinkedInAuth = () => {
    const clientId = '86ykb164n685zp';
    const redirectUri = encodeURIComponent(`${window.location.origin}/linkedin`);
    const scope = encodeURIComponent('openid profile email');
    const state = 'SOME_UNIQUE_STATE';
    const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`;

    const authWindow = window.open(url, 'linkedInAuthWindow', 'width=800,height=600');

    const interval = setInterval(() => {
      try {
        if (authWindow.location.href.indexOf(`${window.location.origin}/linkedin`) !== -1) {
          clearInterval(interval);
          const url = new URL(authWindow.location.href);
          const code = url.searchParams.get('code');
          localStorage.setItem('linkedin_auth_code', code);
          setAccessCode(code); // Store the code in state
          authWindow.close();
          //fetchLinkedInData(code);
        }
      } catch (e) {
        alert("failed")
        clearInterval(interval);
        authWindow.close();
      }
    }, 8000);
};


  return (
      <img
          onClick={openLinkedInAuth}
          src={linkedin}
          alt="Sign in with LinkedIn"
          style={{ maxWidth: '100%', cursor: 'pointer', borderRadius: 10, marginTop: 10 }}
      />
  );
};

export default LinkedInAPIButton;