import React, { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import Prism from 'prismjs';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import 'prismjs/themes/prism-dark.css';
// import 'prismjs/themes/prism-okaidia.css';
import 'prismjs/components/prism-python.js';
import 'prismjs/components/prism-javascript.js';
import 'prismjs/components/prism-typescript.js';
import 'prismjs/components/prism-css.js';
import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-cshtml';
import 'prismjs/components/prism-java.js';
import 'prismjs/components/prism-c.js';
import YouTubePlayer from 'react-player/youtube';
import YouTubeVideo from './extensions/YouTubeVideo';
import PlaidButton from './PlaidAPI/PlaidButton';


function ThreadFormat(text, title, indexed, addJob, user, isMobile, userGrowthColor, documents) {

  const [ show, setShow ] = useState(false);
  const [ showCount, setShowCount ] = useState(10);
  const [isVisible, setVisibility] = useState(false);

  const urlRegex = /(https?:\/\/[^\s()]*)(?=\s|\)|$)/gi;
  const imageRegex = /(https?:\/\/[^\s()]*\.(?:jpg|jpeg|png|gif|bmp|webp))(?=\s|\)|$)/gi;
  const emailRegex = /\b[\w\.-]+@[\w\.-]+\.\w{2,}\b/g;
  const codeBlockRegex = /```(\w+)\n([\s\S]+?)```/gs;
  const markdownUrlRegex = /\[(http(s)?:\/\/[^\]]+)\]\(\1\)/g;
  const linkWithParenthesesRegex = /\[.*?\]\(\s*(https?:\/\/[^\s()]*)\s*\)\.?/gi;
  const YouTubeRegex = /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})(?:\S+)?$/;
  const jobRegex = /%%(.*?)%%/g;
  const executeJobRegex = /##(.*?)##/g;
  const executeFinancesRegex = /\$\$(.*?)\$\$/g;
  const urls = text.match(urlRegex) || [];
  const emails = text.match(emailRegex) || [];
  const codeBlocks = text.match(codeBlockRegex) || [];
  const images = text.match(imageRegex) || [];
  const jobs = text.match(jobRegex) || [];
  const youtubeLinks = text.match(YouTubeRegex);

  let firstImageOrVideo = null; // Store the first image or video URL here

  function toggle() {
      if (show) setShowCount(10);
      if (!show) setShowCount(100);
      setShow(!show);
      setVisibility(!isVisible);
  }

  function executeIt() {
    let regex = /JOB\s#(.*?)\s\(/;
    let match = regex.exec(text);

    let start = text.indexOf("**JOB** #") + 9; // "+ 5" to account for the length of "JOB #"
    let end = text.indexOf("(");
    let jobNumber = text.slice(start, end).trim();


    let job_list = [];
    jobs.forEach(job => { 
      const cleanedJob = job.replace(/%%/g, '');
      let o = {
        task: cleanedJob,
        status: "Not Started",
        progress: 0,
        description: `${cleanedJob} on: ${title}`
      }
      job_list.push(o);
    });

    if(job_list.length == 0) {
      let o = {
        task: "Research",
        status: "Not Started",
        progress: 0,
        description: `Researching information on: ${title}`
      }
      job_list.push(o);
    }

     addJob({
          jobNo: jobNumber,
          jobName: "Job " + indexed,
          jobStatus: "Running",
          jobType: "job",
          jobOwner: user,
          jobBackground: text,
          jobTitle: title,
          Jobs: job_list
      });
  }



  let formattedText = text;
  formattedText = text.replace(linkWithParenthesesRegex, (match, url) => url);
  
  console.log(codeBlocks)

  urls.forEach(url => {
    formattedText = formattedText.replace(url, `\n${url}\n\n`);
  });

  images.forEach(image => {
    formattedText = formattedText.replace(image, `\n${image}\n\n`);
  });

  emails.forEach(email => {
    formattedText = formattedText.replace(email, `\n${email}`);
  });

  // jobs.forEach(job => {
  //   const cleanedJob = job.replace(/%%/g, '');
  //   formattedText = formattedText.replace(job, cleanedJob);
  // });

  formattedText = formattedText.replace(markdownUrlRegex, '$1');
  let inCodeBlock = false;
  let language, code;

  const paragraphArray = formattedText.split('\n');
  console.log(paragraphArray);

  const formattedParagraph = paragraphArray.map((line, index) => {
    if (line.match(imageRegex)) {
      if (!firstImageOrVideo){
          firstImageOrVideo = line;
      }

      if (firstImageOrVideo != line) {
          return (
            <div key={index}>
              <img src={line} alt="" style={{ width: '100%', wordWrap: "break-word" }} />
            </div>
          )
      }
    } else if (line.match(jobRegex)) {
      const job = line.match(jobRegex)[0].replace(/%%/g, '');
      // return (
      //   <div key={index} className="get-job">
      //     {job}
      //   </div>
      // );
    } else if (line.match(executeJobRegex)) {
      const execute = line.match(executeJobRegex)[0].replace(/##/g, '').replace(/\*/g, '').replace(/"/g, '');
      return (
        <div key={index} className={"execute-job execute-job-" + indexed} onClick={executeIt} style={{ fontWeight: "bold", backgroundColor: userGrowthColor }} >
          {execute}
        </div>
      );
    } else if (line.match(executeFinancesRegex)) {
      return (
        <PlaidButton key={index} />
      );

    } else if (line.startsWith('http://') || line.startsWith('https://')) {
      if (line.match(YouTubeRegex)) {
          if (! firstImageOrVideo) {
              firstImageOrVideo = line;
          }
          
          if (firstImageOrVideo != line) {
            return <YouTubeVideo key={index} url = {line} isMobile = {isMobile} videoId={line.match(YouTubeRegex)[1]} />
          }
      } else {
        return (
          <div key={index} style={{ color: '#72aceb' }}>
            <a style={{ color: '#72aceb', wordWrap: "break-word" }} href={line} target='_blank' rel='noopener noreferrer'>
              {line}
            </a>
          </div>
        );
      }
    } else if (line.match(emailRegex)) {
      const email = line.match(emailRegex)[0];
      return (
        <div key={index} style={{ color: '#72aceb' }}>
          <a style={{ color: '#72aceb', wordWrap: "break-word" }}href={`mailto:${email.trim()}`}>{email.trim()}</a>
        </div>
      );
    } else if (line.startsWith('#')) {
      const titleText = line.substring(1);
      return (
        <div key={index}>
          <strong>{titleText}</strong>
        </div>
      );
    } else if (inCodeBlock) {
      if (line.endsWith('```')) {
        inCodeBlock = false;
        const highlightedCode = Prism.highlight(code, Prism.languages[language], language);
        return (
          <div key={index} style={{ backgroundColor: '#1a1c21', border: '1px solid transparent', borderRadius: '12px', borderBottomLeftRadius: 0, padding: '10px', margin: '10px 0', fontFamily: 'Courier, monospace', position: 'relative' }}>
            <button onClick={() => copy(code)} style={{ position: 'absolute', right: '10px', top: '10px', backgroundColor: '#4CAF50', color: 'white', padding: '5px 10px', borderRadius: '4px', border: 'none', cursor: 'pointer' }}>Copy</button>
            <div style={{ backgroundColor: '#1a1c21', color: '#fff', padding: '5px', fontFamily: 'Courier, monospace', borderBottom: '1px solid #24262d', fontWeight: 'bold' }}>{language}</div>
            <pre style={{ backgroundColor: '#1a1c21', color: '#fff', padding: '10px', margin: '0', fontFamily: 'Courier, monospace', wordBreak: "break-all", overflowX: "auto" }} dangerouslySetInnerHTML={{ __html: highlightedCode }} />
          </div>
        );
      } else {
        code += '\n' + line;
        return null;
      }
    } else if (line.startsWith('```')) {
        inCodeBlock = true;
        language = line.slice(3);
        code = '';
        return null;

    } else {
      // Convert **name** to <b>name</b>
      const boldTextRegex = /\*\*(.*?)\*\*/g;
      const formattedLine = line.split(boldTextRegex).map((part, i) => {
          if (i % 2 === 1) {
              // Odd-indexed parts are the **name** sections
              if (part.includes('"')){
                part = part.replace('"', '').replace('"', '');
                return <b key={i} style={{ color: "#FAE69E", fontSize: 16, fontFamily: "'Arial', 'Helvetica', sans-serif" }}>{part}</b>;
              }
              else 
              {
                return <b key={i} style={{ color: "#cbc19d", fontSize: 14 }}>{part}</b>;
              }
          } else {
              return part;
          }
      });

      return (
        
        <div key={index} className='core-message' style={{ margin: '10px 0', marginBottom: 20, wordWrap: "break-word", fontSize: 14 }}>
          {index < showCount && formattedLine}
        </div>
      );
    }
  });


  return (
    <div style={{ position: 'relative'}}>
      {firstImageOrVideo ? (
        firstImageOrVideo.match(imageRegex) ? (
          <div>
            <img src={firstImageOrVideo} alt="" style={{ width: '100%', wordWrap: "break-word" }} />
          </div>
        ) : (
          <YouTubeVideo url={firstImageOrVideo} isMobile={isMobile} videoId={firstImageOrVideo.match(YouTubeRegex)[1]} />
        )
      ) : null}
      <div style={{ positiion: "relative"}}>
          {user.toLowerCase() == "cofounderai" && paragraphArray.length > showCount && <div style={{ position: 'absolute', right: "50%", bottom: -60, fontSize: 35, rotate: "90deg" }} onClick ={toggle}>
              {!isVisible && <span>&#x2771;</span>}
              {isVisible && <span>&#x2770;</span>}
          </div>}
      </div>
      {<div>
          {documents?.map((x, i) => {
              if (i > 0) return
              return <Snippet y = {x} isMobile = {isMobile} firstImageOrVideo = {firstImageOrVideo} />
          })}
      </div>}
      <div onClick = {toggle}>
          {formattedParagraph}
      </div>

    </div>
  );
}


const Snippet = (props) =>{
  const x = props.y;

  const [dimensions, setDimensions] = useState({ width: 1, height: 1 });

  const [show, setShow] = useState(false);
  function showButton () {
      setShow(!show);
  }

  const YouTubeRegex = /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})(?:\S+)?$/;
  let show_video = false;
  if(x.loginurl.match(YouTubeRegex) && (x.loginurl.startsWith('http://') || x.loginurl.startsWith('https://'))) {
      show_video = true;
  }

  if (props.firstImageOrVideo) {
      if (props.firstImageOrVideo == x.loginurl) {
          show_video = false;
      }
  }

  const imageUrl = x.image;

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setDimensions({ width: img.width, height: img.height });
    };
  }, [imageUrl]);

  let width = 100; let height = 100; let full_image = false;
  const ratio = dimensions.width / dimensions.height;
  if (ratio >= 1.5) {
      width = "100%";
      height = "100%"
      full_image = true;
  }

  if (x.title == "") return "" 
  return <div style={{  marginTop: 20, backgroundColor: "#161718", borderRadius: 10, padding: 20 }} onClick={showButton}>
      
      {show_video && <YouTubeVideo key={0} url = {x.loginurl} isMobile = {props.isMobile} videoId={x.loginurl.match(YouTubeRegex)[1]} />}
          
      <div style = {{ display: "inline-flex" }}> 
        <img src={x.image} style = {{ width: width, height: height, marginRight: 15, borderRadius: 10 }}/>
        {!full_image && <div style= {{ fontWeight: "bold", fontSize: 16 }}>{x.title != "" && x.title}</div>}
      </div>

      {full_image && <div style= {{ fontWeight: "bold", fontSize: 16, marginTop:10 }}>{x.title != "" && x.title}</div>}
      
      {show && <div style={{ marginLeft: 30, marginTop: 20, wordBreak: 'break-word' }}>
          <div style = {{ marginTop: 20 }}><span style = {{ fontWeight: "bold", color: "#cbc19d" }}>Founder:</span> {x.founder}</div>
          <div style = {{ marginTop: 20 }}><span style = {{ fontWeight: "bold", color: "#cbc19d" }}>LinkedIn:</span> <a href={x.linkedin}>{x.linkedin}</a></div>
          <div style = {{ marginTop: 20 }}><span style = {{ fontWeight: "bold", color: "#cbc19d" }}>Offering:</span> {x.offering}</div>
          <div style = {{ marginTop: 20 }}><span style = {{ fontWeight: "bold", color: "#cbc19d" }}>Website:</span> <a href={x.loginurl}>{x.loginurl}</a></div>
          <div style = {{ marginTop: 20 }}><span style = {{ fontWeight: "bold", color: "#cbc19d" }}>Summary:</span> {x.summary}</div>
      </div>}
  </div>
}

export default ThreadFormat;