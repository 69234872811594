import React, { useState, useEffect, useRef } from 'react';
import ThreadFormat from './ThreadFormat';

const JobTask = (props) => {
    let styleBlock = {
        position: "relative"
    }

    const [isVisible, setVisibility] = useState(false);

    let style = {
        // maxHeight: isVisible ? 40 : "auto",
        // overflow: "hidden",
    }

    let styleExpand = {
        marginTop: 20,
        fontSize: 14,
        display: !isVisible ? "none" : "block",
    }

    function expand() {
        setVisibility(!isVisible);
    }

    function getTime(property) {
        const now = new Date();

        if (!property || typeof property !== 'string') {
            return now.toLocaleTimeString();
        }
        
        const ttimestamp = new Date(property);

        if (ttimestamp.toDateString() === now.toDateString()) {
            return ttimestamp.toLocaleTimeString();
        }
        else 
        {
            return ttimestamp.toLocaleDateString(undefined, { dateStyle: 'short', timeStyle: 'short' });
        }
    }
 
    const task = props.task;
    return (
        <div className="job-task" style={styleBlock} key={props.index} onClick={expand}>
            <div style ={style}>
                <div style={{ fontSize: 16 }}>{task.task}</div>
                <div style={{ position: 'absolute', right: 20, top: 0, fontSize: 25, rotate: "90deg" }}>
                    {!isVisible && <span>&#x2771;</span>}
                    {isVisible && <span>&#x2770;</span>}
                </div>
                <div className='job-details' style={styleExpand}>
                    <div className='job-details-item'>
                        <div className='job-details-item-title'><span style={{fontWeight: "normal"}}>Status: </span>{task.status}</div>
                        <div className='job-details-item-title'><span style={{fontWeight: "normal"}}>Completed: </span>{task.progress}%</div>
                        <div style={{ fontWeight: "normal" }}>
                            {task.description}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  };
  

  export default JobTask;