import React, { Component } from "react";
import axios from 'axios'
import packageJson from '../../package.json';
import Nextstep from "./Nextstep";


class Recommend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            whiteSpace: 'nowrap'
        }

        this.openBox = this.openBox.bind(this);
    }

    openBox() {
        if (this.state.open) {
            this.setState({ open: false, whiteSpace: 'nowrap' });
        }
        else 
        {
            this.setState({ open: true, whiteSpace: 'unset' });
        }
    }

    render() {
        let display = "none";
        if (this.state.open) {
            display = "block"
        }

        const truncateWords = {
            width: 'inherit',
            whiteSpace: this.state.whiteSpace,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginTop: 15,
            display: display
        }

        const recommendMain = {
            borderRadius: 5,
            border: '1px solid #c7cbd5',
            padding: 20,
            marginBottom: 30,
            borderRight: "10px solid #ff801e",
            backgroundColor: "#fff"
        }

        const title = {
            marginTop: 15,
            marginBottom: 15
        }

        function diff(start, end) {
            var startDate = new Date(start);
            var endDate = new Date(end);
            var diff = endDate.getTime() - startDate.getTime();
            var hours = diff / 1000 / 60 / 60;
            var minutes = Math.floor(diff / 1000 / 60);
        
            // If using time pickers with 24 hours format, add the below line get exact hours

            if (hours > 24 * 60) {
                return Math.floor(hours/24/30) + " months ago";
            }

            if (hours > 24 * 30) {
                return Math.floor(hours/24/30) + " month ago";
            }

            if (hours > 24 * 14) {
                return Math.floor(hours/24/7) + " weeks ago";
            }

            if (hours > 24 * 7) {
                return Math.floor(hours/24/7) + " week ago";
            }

            if (hours > 48) {
                return Math.floor(hours/24) + " days ago";
            }

            if (hours > 23) {
                return Math.floor(hours/24) + " day ago";
            }

            if (hours > 2) {
                return Math.floor(hours) + " hours ago";
            }

            if (hours >= 1) {
                return Math.floor(hours) + " hour ago";
            }

            if (minutes < 60) {
                return Math.floor(minutes) + " minute(s) ago";
            }
        }

        console.log(JSON.stringify(this.props.dataset));

        if (this.props.dataset !== undefined) {
            const desc = this.props.dataset?.description.split(' ');

            let titleDesc = desc.splice(-15).join(' ');
            titleDesc = titleDesc.charAt(0).toUpperCase() + titleDesc.slice(1) + ".";

            let description = this.props.dataset?.description;
            description = description.charAt(0).toUpperCase() + description.slice(1);

            let query = this.props.dataset?.query;
            query = query.charAt(0).toUpperCase() + query.slice(1);

            console.log(this.props.date);

            let time = diff(new Date(this.props.date).getTime() - 3.5*60*60*1000, Date.now());

        return (<div className="history-row" style={recommendMain} onClick={this.openBox}>
                <small style={{ color: "grey", right: 0, paddingBottom: 20 }}>{time}</small>
                <div style={{marginTop: 10, marginBottom: 30 }}>
                    <b>Query:  </b><span>{query}</span>
                </div>

                <div style={truncateWords}>
                    <h4 style={title}>{titleDesc}</h4>
                    <div style={{ marginBottom: 20 }}>
                        <b>Response</b>: { description}
                    </div>
                    <div>
                        <b>How</b>: { this.props.dataset.how}
                    </div>
                    <div style={{ fontWeight: 'bold', marginTop: 20 }}>
                        impact: {this.props.dataset.perspectiveROI.toFixed(2)}
                    </div>  
                    <div style={{ fontWeight: 'bold' }}>
                        ROI/RISK: {this.props.dataset.opportunityCost.toFixed(2)}
                    </div>  
                    <div style={{ fontWeight: 'bold' }}>
                        Opportunity Cost: {Math.abs(this.props.dataset.overallROI).toFixed(2)}
                    </div> 

                    <div style={{ marginTop: 15 }}>
                        {this.state.open && <iframe width="560" height="315" src={this.props.dataset.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>}
                    </div>
                </div>
            </div>
            );
        }
        else {
            return <div></div>
        }
    }
}


export default Recommend;