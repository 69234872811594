import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceDot, ReferenceLine, Rectangle } from 'recharts';


const generatePortfolioData = (days, startValue, dynamic) => {
    let data = [];
    let currentValue = startValue;
    const today = new Date();
    
    for (let i = days - 1; i >= 0; i--) {
      const d = new Date(today);
      d.setDate(today.getDate() - i);
      
      const date = `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')} 12:00:00`;
      
      currentValue += Math.floor(Math.random() * 100) - 50;  // random fluctuation between -50 and +50

      if (dynamic !== undefined && ! dynamic) {
          currentValue = 100
      }

      data.push({ date: date, value: currentValue });
    }
    
    return data;
  };

function calculateDateSpan(data) {
    if (data.length === 0) {
      return 'No data available';
    }
  
    // Sort the data by date in ascending order
    data.sort((a, b) => new Date(a.date) - new Date(b.date));
  
    // Get the earliest and latest dates
    const earliestDate = new Date(data[0].date);
    const latestDate = new Date(data[data.length - 1].date);
  
    // Calculate the date span in days
    const dateSpanInDays = (latestDate - earliestDate) / (1000 * 60 * 60 * 24);
  
    if (dateSpanInDays <= 1) {
      return '1D';
    } else if (dateSpanInDays <= 8) {
      return '1W'
    } else if (dateSpanInDays <= 30) {
      return '1M';
    } else if (dateSpanInDays <= 90) {
      return '3M';
    } else if (dateSpanInDays <= 360) {
      return '1Y';
    } else {
      return '1W';
    }
}
  

const FinancialChart = (props) => {
    let data = [];
    
    const [timeframe, setTimeframe] = useState('1W');
    const [portfolioData, setPortfolioData] = useState([]); // Generates 365 days of data
    const [filterPortfolio, setfilterPortfolio] = useState([]);
    const [portfolioBaseline, setPortfolioBaseline] = useState(0);
    const [portfolioLast, setPortfolioLast] = useState(1);
    const [timeframeString, setTimeFrameString] = useState("Past Week");
    const [ mainScore, setMainScore ] = useState(0);
    const [ realData, setRealData ] = useState(false);

    if (props.chart !== undefined && props.chart.length > 0) {
        data = props.chart;

        if (data != portfolioData) {
            setPortfolioData(data);
            setRealData(true);
        }
    }
    else 
    {
        if (props.spark) {
            data = generatePortfolioData(30, 1000);
        }
        else 
        {
            data = generatePortfolioData(2000, 0, false);
        }
    }

    useEffect(() => {
        // let tf = calculateDateSpan(data);
        // let tf_string = filterDataByTimeFrameString(tf);
        
        // setTimeframe(tf);
        // setTimeFrameString(tf_string);

        let filtered = filterDataByTimeframe(timeframe);
        const firstDataPoint = filtered[0];
        let baseline = firstDataPoint ? firstDataPoint.value : 1;
        baseline = baseline == 0 ? 1: baseline;
        //alert(baseline);
        
        setPortfolioBaseline(baseline);
        setfilterPortfolio(filtered);

        const lastDataPoint = portfolioData.length > 0 ? portfolioData[portfolioData.length - 1].value : 0;
        setPortfolioLast(lastDataPoint);
      
    }, [timeframe, data]);


    useEffect(() => {
        // let tf = calculateDateSpan(data);
        // let tf_string = filterDataByTimeFrameString(tf);
        
        // setTimeframe(tf);
        // setTimeFrameString(tf_string);
      
    }, [timeframe]);

    // Determine line and text color based on last value.
    //const lastDataPoint = portfolioData && portfolioData.length > 0 ? portfolioData[portfolioData.length - 1] : { value: 0 };
   
    //alert(JSON.stringify(lastDataPoint));
    const lastDataPoint = portfolioLast ?? 1;
    let baseline = portfolioBaseline ?? 1; // Set your baseline value here
    baseline = baseline == 0 ? 1: baseline;
    //if (props.spark && data != portfolioData) alert(portfolioBaseline)
    const isAboveBaseline = lastDataPoint >= baseline || portfolioData.length == 0;
    let diff = Math.abs(lastDataPoint - baseline).toFixed(2) ?? 0;
    diff = !isNaN(diff) ? diff: 0
    const percent = (diff / baseline).toFixed(2);
    let effective_percentage = ((lastDataPoint - baseline)/baseline);

    effective_percentage = !isNaN(effective_percentage) ? effective_percentage: 0
    if (isAboveBaseline) {
      effective_percentage = Math.abs(effective_percentage);
    }
    else 
    {
      effective_percentage = -Math.abs(effective_percentage);
    }

    if (effective_percentage == Infinity) {
        effective_percentage = 0
    }

    if (effective_percentage == -Infinity) {
        effective_percentage = 0
    }


    effective_percentage = effective_percentage.toFixed(2);

    let current = lastDataPoint ?? 0;
    current = !isNaN(current) ? current: 0
    const formattedCurrent = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(current);
    const formattedDiff = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(diff);
    const redColor = '#e95333'; //"#9a9a9a";
    const lineColor = isAboveBaseline ? '#008000' : redColor;
    const textColor = isAboveBaseline ? '#00C805' : redColor;
    const buttonColor = isAboveBaseline ? '#008000' : redColor;
    const progressColor = isAboveBaseline ? '#fff' : "#fff";
    
    if (!props.spark) props.setUserGrowthColor(buttonColor);

    if (!props.spark && realData && portfolioData.length > 0 && mainScore == 0) {
        // let len = portfolioData.length > 0 ? portfolioData.length: 1;
        // let sum = portfolioData.reduce((a, b) => a + b.value, 0)/ len;
        // let progress = Math.round(sum / 100 * 20);
        // progress = progress > 0 ? progress: 1;

        let len = portfolioData.length > 0 ? portfolioData.length : 1;
        let sum = portfolioData.reduce((a, b) => a + b.value, 0) / len;
        
        // Calculate the average of the last half of the data
        let lastHalf = portfolioData.slice(Math.max(0, len / 2));
        let sumLastHalf = lastHalf.reduce((a, b) => a + b.value, 0) / lastHalf.length;
        
        // Calculate the average of the last 1/4 of the data
        let lastQuarter = portfolioData.slice(Math.max(0, len / 4 * 3));
        let sumLastQuarter = lastQuarter.reduce((a, b) => a + b.value, 0) / lastQuarter.length;
        
        // Calculate the final score by averaging the three averages
        let finalScore = (sum + sumLastHalf + sumLastQuarter) / 3;
        
        // Round progress
        let progress = Math.round(finalScore / 100 * 20);
        progress = progress > 0 ? progress : 1;
              
        setMainScore(progress);
    }


    useEffect(() => {
      if (props.getChartPercentage && props.topic) props.getChartPercentage(props.topic, effective_percentage/10);
    }, [effective_percentage]);
 
    const TimeFilters = ({  }) => {
        let iList = [
            { tf: '1D', selected: false },
            { tf: '1W', selected: false },
            { tf: '1M', selected: false },
            { tf: '3M', selected: false },
            { tf: '1Y', selected: false },
            { tf: '5Y', selected: false }
        ];

        return (<div style={{ 
              color: 'white', 
              position: "absolute", 
              bottom: 20, 
              left: "10%", 
              width: "80%", 
              fontSize: 13,
              display: 'flex', 
              justifyContent: 'space-between',
              paddingBottom: 20,
              borderBottom: "1px solid #26272a",
              zIndex: 200
          }}>

          {iList.map(x => {
                if (x.tf == timeframe) {
                    return <button onClick={setTimeframeNow} tf = {x.tf} style={{ backgroundColor: lineColor, color: "#fff", border: "unset", borderRadius: 3 }}>{x.tf}</button>
                }
                else
                {
                    return <button onClick={setTimeframeNow} tf = {x.tf} style={{ backgroundColor: "transparent", color: textColor, border: "unset", borderRadius: 3 }}>{x.tf}</button>
                }
          })};  
        </div>);
    };



  function setTimeframeNow(e) {
     if (e && e.target) {
        let tf = e.target.getAttribute('tf');
        setTimeFrameString(filterDataByTimeFrameString(tf));
        setTimeframe(tf);
     }
  }

  // For now, using all data
  const filterDataByTimeframe = (tf) => {
    let filterDays = 0;
  
    // Convert timeframe to the number of days
    switch (tf) {
      case '1D':
      case '-1':
        filterDays = 1;
        break;
      case '1W':
      case '-7':
        filterDays = 7;
        break;
      case '1M':
      case '-30':
        filterDays = 30;
        break;
      case '3M':
      case '-90':
        filterDays = 90;
        break;
      case '1Y':
      case '-365':
        filterDays = 365;
        break;
      case '5Y':
      case '-1825':
        filterDays = 1825;
        break;
      default:
        filterDays = 0; // show all data
    }
  
    // Filter the portfolio data
    if (filterDays === 0) {
      return portfolioData;
    } else {
      return portfolioData.filter(item => {
        const dataDate = new Date(item.date);
        const currentTime = new Date();
        const timeDifference = Math.abs(currentTime - dataDate);
        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        return daysDifference <= filterDays;
      });
    }
  };
  


  const filterDataByTimeFrameString = (tf) => {
      switch (tf) {
        case '1D':
          return "Today";
        case '1W':
          return "Past Week";
        case '1M':
          return "Past Month";
        case '3M':
          return "Past 3 Month";
        case '1Y':
          return "Past Year";
        case '5Y':
          return "All Time";
        default:
          return "All Time";
      }
  };


  const filteredData = filterPortfolio;

  const minY = Math.min(...filteredData.map(d => d.value));
  const maxY = Math.max(...filteredData.map(d => d.value));
  const padding = (maxY - minY) * 0.1;  // Add 10% padding
  
  function getFourLetters() {
    const user = JSON.parse(localStorage['user'])

    if (!user || !user.displayName) {
        return "Self-Valuation";
    }

    let name = user.displayName;
    const nameParts = name.trim().split(" ");
    
    if (nameParts.length >= 2) {
        return (nameParts[0].slice(0, 2) + nameParts[1].slice(0, 2)).toUpperCase();
    } else {
        return nameParts[0].slice(0, 4).toUpperCase();
    }
  }

    
  function getUserName() {
    const user = JSON.parse(localStorage['user'])

    if (!user || !user.displayName) {
        return "Milestones";
    }

    let name = user.displayName;

    const nameParts = name.trim().split(" ");

    return nameParts[0];
  }

  function growth() {
      alert("You have to keep track of you self valuation. You are growing a startup, education or building your career. You need to build this. We try to track your growth so that you keep up and make the best of it all.")
  }

  return (
    <div style={{ backgroundColor: 'transparent', color: 'transparent', padding: '1rem', position: "absolute", top: 140, left: -20, zIndex: 0 }}>
      <div style = {{ position: "absolute", top: -130, left: 40 }}>
        {!props.spark && <div style={{ fontSize: 24, color: "#fff" }} onClick={growth}>Jobs Completed</div>}
        {!props.spark && <div style={{ fontSize: 35, color: "#fff", marginTop: -5 }}>
          <span style = {{ fontSize: 38, color: progressColor }}>{mainScore}%</span>
          <span style = {{ fontSize: 28, color: progressColor, marginLeft: 5 }}>ROI</span>
          {/* <span style = {{ fontSize: 26, color: progressColor, marginRight: 5, marginLeft: 5 }}>of</span>
          <span style = {{ fontSize: 36, color: progressColor }}>100</span> */}
        </div>}
        {!props.spark && <div style={{ fontSize: 14, color: "#fff", marginTop: 5 }}>
            <span>
                {isAboveBaseline && <svg width="10" height="10">
                    <polygon points="5,0 10,10 0,10"  fill={textColor}></polygon>
                </svg>}
                {! isAboveBaseline &&
                <svg width="10" height="10">
                    <polygon points="5,10 0,0 10,0" fill={textColor}></polygon>
                </svg>  
                }
            </span>
            <span style={{ marginLeft: 5, color: textColor, fontWeight: "bold" }}>{formattedDiff} ({effective_percentage}%)</span>
            <span style={{ marginLeft: 5 }}>{timeframeString}</span>
        </div>}
      </div>
      {!props.spark && <TimeFilters  />}
      <LineChart
          width={props.width}
          height={props.height}
          data={filteredData}
          margin={{ top: 5, right: 15, left: -45, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="transparent" stroke="transparent" />
          <XAxis dataKey="date" stroke="transparent" />
          <YAxis stroke="transparent" domain={[minY - padding, maxY + padding]} />
          <Line dataKey="value" stroke={lineColor} r={0} strokeWidth={2} /> 
          <ReferenceLine y={baseline} stroke="grey" strokeDasharray="3 3" />

          {/* Adding a vertical dashed grey line */}
          <ReferenceLine x={props.width * (2 / 3)} stroke="grey" strokeDasharray="3 3" />
          
          {/* Adding the yellowish transparent rectangle */}
          <Rectangle
              x={props.width * (2 / 3)}
              y={0}
              width={props.width / 3}
              height={props.height}
              fill="rgba(255, 255, 0, 0.8)" // Yellowish transparent hue
          />

          {!props.spark && <ReferenceDot 
              className="animated-dot" style={{ textAlign: "center", marginLeft: 14 }}
              x={portfolioData.length > 0 ? portfolioData[portfolioData.length - 1].date : "today"} 
              y={portfolioData.length > 0 ? portfolioData[portfolioData.length - 1].value : 0} 
              stroke={lineColor ? lineColor : "red"}
          >
            {/* <animate attributeName="r" from="2" to="20" dur="0.5s" begin="0s" repeatCount="indefinite" /> */}
          </ReferenceDot>}
      </LineChart>
    </div>
  );
};

export default FinancialChart;
